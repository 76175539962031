<script>
export default {
    name: "DetailsComponent",
    props: {
       header: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            showDetails: false,
        };
    },
};

</script>

<template>
    <b-card no-body style="border-radius: 12px; min-width: 100%;">
        <template #header>
            <b-row>
                <b-col cols="10" class="d-flex align-items-center">
                    <h6 class="mb-0">{{header}}</h6>
                </b-col>
                <b-col cols="2" class="d-flex justify-content-end">
                    <b-button
                        variant="outline-dark"
                        @click="showDetails = !showDetails"
                    >
                        <i
                            v-if="showDetails"
                            class="fa fa-chevron-up"
                        ></i>
                        <i
                            v-else
                            class="fa fa-chevron-down"
                        ></i>
                    </b-button>
                </b-col>
            </b-row>
        </template>
        <slot v-if="showDetails"></slot>
    </b-card>
</template>

<style scoped>

</style>
