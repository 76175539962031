<template>
    <details-component header="Skills" style="min-width: 100%">
        <b-list-group flush style="padding: 24px">
            <b-list-group-item v-for="(skill, index) in visibleSkills" :key="index">
                <b-card-text>
                    <h6><strong>{{ skill.name }}</strong></h6>
                    <p v-if="skill.endorser_count">
                        <i class="fa fa-user mr-2"></i>{{ skill.endorser_count }}
                        <template v-if="skill.endorser_count > 1">endorsements</template>
                        <template v-else>endorsement</template>
                    </p>
                </b-card-text>
            </b-list-group-item>
        </b-list-group>

        <b-button variant="light" class="btn-show-details" v-if="skills.length > 4" @click="showModal = true">Show all
            {{ skills.length }} skills <i class="fa fa-arrow-right" style="font-weight: lighter"></i></b-button>

        <Modal id="skills_modal" title="Skills" :visible.sync="showModal" @close="showModal = false">
            <b-list-group flush>
                <b-list-group-item v-for="(skill, index) in skills" :key="index">
                    <b-card-text>
                        <h6><strong>{{ skill.name }}</strong></h6>
                        <p v-if="skill.endorser_count">
                            <i class="fa fa-user mr-2"></i>{{ skill.endorser_count }}
                            <template v-if="skill.endorser_count > 1">endorsements</template>
                            <template v-else>endorsement</template>
                        </p>
                    </b-card-text>
                </b-list-group-item>
            </b-list-group>
        </Modal>
    </details-component>
</template>

<script>
import DetailsComponent from "@/components/scraper/linkedin/results/utils/details_component.vue";
import {BButton, BCardText, BListGroup, BListGroupItem} from 'bootstrap-vue';
import Modal from "@/components/scraper/linkedin/results/utils/modal.vue";

export default {
    name: "Skills",
    components: {DetailsComponent, BButton, BListGroup, BListGroupItem, BCardText, Modal},
    props: {
        skills: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            showModal: false,
        };
    },
    computed: {
        visibleSkills() {
            return this.skills.slice(0, 4);
        },
    },
};
</script>

<style scoped>
.btn-show-details {
    margin: 0;
    width: 100%;
    border-radius: 0 0 12px 12px;
}

</style>
