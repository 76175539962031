<script>
import LinkedinActivity from "@/components/scraper/linkedin/LinkedinActivity.vue";
import DetailsComponent from "@/components/scraper/linkedin/results/utils/details_component.vue";
import Modal from "@/components/scraper/linkedin/results/utils/modal.vue";

export  default {
    name: "Activities",
    components: {Modal, DetailsComponent, LinkedinActivity},
    computed : {
     visibleActivities() {
         return this.activities.slice(0,3);
     },
    },
    props: {
        activities: {
            type: Array,
            required: true,
        },
    },
    data () {
        return {
            showModal: false,
        };
    },
};
</script>

<template>
<details-component header="Activity">
    <template>
        <template v-if="activities.length < 3">
            <div v-for="(activity, index) in activities" :key="index" >
                <linkedin-activity :activity="activity" :type="activity.activity_type"/>
            </div>
        </template>
        <template v-else>
            <div v-for="(activity, index) in visibleActivities" :key="index">
                <linkedin-activity :activity="activity" :type="activity.activity_type"/>
            </div>
            <b-button variant="light" class="btn-show-details" @click="showModal = true">Show all {{ activities.length }} activities <i class="fa fa-arrow-right" style="font-weight: lighter"></i></b-button>
            <Modal id="activities_modal" title="Activities" :visible.sync="showModal" @close="showModal = false">
                <b-list-group flush>
                    <b-list-group-item v-for="(activity, index) in activities" :key="index">
                        <linkedin-activity :activity="activity" :type="activity.activity_type"/>
                    </b-list-group-item>
                </b-list-group>
            </Modal>
        </template>

    </template>

</details-component>
</template>

<style scoped>

</style>
