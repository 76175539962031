<template>
    <!-- The Modal -->
    <div class="modal fade" id="configEditModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Cookie Configuration</h4>
                    <button type="button" class="close" data-dismiss="modal" @click="$emit('close')">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row editor">
                            <div class="col-12 col-sm-12">
                                <div class="form" role="form" style="">
                                    <!-- section one -->
                                    <div class="row">
                                        <div class="col-12"><h5>Settings</h5><br></div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Platform <strong class="text-danger">*</strong></label>
                                                <select v-model="item.platform" class="form-control text-capitalize required">
                                                    <option v-for="(platform, index) in platforms" :key="index" :value="platform">{{ platform }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Email / Phone <strong class="text-danger">*</strong></label>
                                                <input type="text" v-model="item.email" class="form-control" />
                                            </div>
                                        </div>

                                        <div v-if="item.platform === 'twitter'" class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Username <strong class="text-danger">*</strong></label>
                                                <input type="text" v-model="item.username" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Password <strong class="text-danger">*</strong></label>
                                                <input type="password" v-model="item.password" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Status <strong class="text-danger">*</strong></label>
                                                <select v-model="item.status" class="form-control text-capitalize required" required>
                                                    <option v-for="(status, index) in statuses" :key="index" :value="status">{{ status }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <label>Cookies <strong class="text-danger">*</strong></label>
                                                <codemirror
                                                    ref="mirror"
                                                    v-model="item.cookies"
                                                    :options="cmOptions"
                                                    @input="onCodeChange"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="row pt-4">
                        <div class="col-12">
                            <div class="form-group text-center">
                                <button v-if="!item._id" type="submit" class="btn btn-primary btn-sm"
                                        @click.prevent="save">
                                    <span class="glyphicon glyphicon-ok"></span> Create Configuration
                                </button>
                                <button v-else type="submit" class="btn btn-primary btn-sm"
                                        @click.prevent="update">
                                    <span class="glyphicon glyphicon-ok"></span> Update Configuration
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import Vue from 'vue';

import { DateRangePickerPlugin } from '@syncfusion/ej2-vue-calendars';
import moment from "moment/moment";

import "codemirror/mode/javascript/javascript";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/the-matrix.css";

const beautify_js = require('js-beautify');

Vue.use(DateRangePickerPlugin);
let infiniteScroll = require('vue-infinite-scroll');

export default {
    name: "ConfigModal",
    props: [
        "item",
        "save",
        "update"
    ],
    directives: {
        infiniteScroll
    },
    components: { },
    data() {
        return {
            args: {},
            ready: false,
            loading: false,
            cursor: null,
            search: null,
            isLoading: false,
            cancelTokenSource: null,
            providers: [],
            countries: [],
            states: [],
            cities: [],
            users: [],
            fbp: ["Posts", "Events", "People", "Photos", "Groups", "Videos"],
            twp: ["Latest", "Media", "People", "List"],
            types: ["api", "browser"],
            proxies: ["yes", "no"],
            platforms: ["facebook", "twitter"],
            statuses: ["active", "disabled"],
            frequencies: ["hourly", "daily", "monthly"],
            cmOptions: {
                tabSize: 4,
                mode: "text/javascript",
                theme: "the-matrix",
                lineNumbers: true,
                line: true,
                rtlMoveVisually: true,
            },
        }
    },
    mounted() {},
    created() {},
    methods: {
        toDate(date) {
            return moment(date).format('YYYY-MM-DD hh:mm:ss')
        },
        onCodeChange(code) {
            code = beautify_js(code)
            this.item.cookies = code;
        }
    }
};
</script>

<style scoped>
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";

.modal-dialog {
    height: 900px;
    display: flex;
    max-width: 800px;
}
.modal-header {
    background: #0d0d48;
}
.modal-title {
    color: #FFFFFF;
}
.close {
    color: #FFFFFF;
}

.modal-body {
    overflow: scroll;
}
.info-sec i {
    font-size: 100px;
}
.v-select2-container {
    width: 100%; /* Set the width to 100% */
}
.select2-container .select2-selection--single {
    height: 38px; /* Set the width to 100% */
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 38px;
}
</style>
