<template>
    <div class="">
        <ejs-multiselect
            v-model="item.points"
            id="multiselect-checkbox"
            cssClass="e-outline"
            :dataSource="linkedin"
            :placeholder="checkWaterMark"
            :fields="checkFields"
            :mode="multiMode"
            :popupHeight="popupHeight"
            :showDropDownIcon="showDropDownIcon"
            :showSelectAll="showSelectAll"
            :enableSelectionOrder="enableSelectionOrder"
            :filterBarPlaceholder="filterPlaceholder"
            :enableGroupCheckBox="enableGroupCheck"
        ></ejs-multiselect>
    </div>
</template>
<style scoped>
.control-styles {
    margin: 0 auto;
    width: 300px;
    padding-top: 25px;
}

.control-styles .h4 {
    font-size: 16px;
    margin: 0 0 10px;
    font-weight: bold;
}

.e-specific.e-multiselect.e-input-group .e-ddl-icon::before {
    transform: translateY(1px);
}

.e-bigger .e-specific.e-multiselect.e-input-group .e-ddl-icon::before {
    transform: translateY(2px);
}
</style>
<script>
import {CheckBoxSelection, MultiSelectComponent,} from "@syncfusion/ej2-vue-dropdowns";

export default {
    components: {
        "ejs-multiselect": MultiSelectComponent,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            checkFields: {
                groupBy: "category",
                text: "text",
                value: "id",
            },
            checkWaterMark: "Select items",
            multiMode: "CheckBox",
            filterPlaceholder: "Search data points",
            showSelectAll: true,
            showDropDownIcon: true,
            enableSelectionOrder: false,
            enableGroupCheck: true,
            popupHeight: "300px",
            linkedin: [
                {text: "People", category: "People", id: "people"},
                {text: "Overview", category: "People", id: "overview"},
                {text: "About", category: "People", id: "about"},
                {text: "Experience", category: "People", id: "experience"},
                {text: "Skills", category: "People", id: "skills"},
                {text: "Education", category: "People", id: "education"},
                {
                    text: "Received Recommendations",
                    category: "People",
                    id: "received_recommendations",
                },
                {
                    text: "Given Recommendations",
                    category: "People",
                    id: "given_recommendations",
                },
                {
                    text: "Contact Info",
                    category: "People",
                    id: "contact_info",
                },
                {
                    text: "Activity",
                    category: "People",
                    id: "activity",
                },
                {
                    text: "People also viewed",
                    category: "People",
                    id: "people_also_viewed",
                },

                {
                    text: "Courses",
                    category: "People",
                    id: "courses",
                },
                {
                    text: "Events",
                    category: "People",
                    id: "events",
                },

                {
                    text: "Honor and Awards",
                    category: "People",
                    id: "honor_and_awards",
                },
                {
                    text: "Images",
                    category: "People",
                    id: "images",
                },
                {
                    text: "Languages",
                    category: "People",
                    id: "languages",
                },
                {
                    text: "Licenses & Certifications",
                    category: "People",
                    id: "license_and_certifications",
                },
                {
                    text: "Organizations",
                    category: "People",
                    id: "organizations",
                },
                {
                    text: "Volunteer Experience",
                    category: "People",
                    id: "volunteer_experience",
                },
                {
                    text: "Patents",
                    category: "People",
                    id: "patents",
                },
                {
                    text: "Projects",
                    category: "People",
                    id: "projects",
                },
                {
                    text: "Publications",
                    category: "People",
                    id: "publications",
                },
                {text: "Test Scores", category: "People", id: "test_scores"},
                {
                    text: "Interest Influencers",
                    category: "People",
                    id: "people_interest_influencers",
                },
                {
                    text: "Interest Companies",
                    category: "People",
                    id: "people_interest_companies",
                },
                {
                    text: "Interest Groups",
                    category: "People",
                    id: "people_interest_groups",
                },
                {
                    text: "Interest Newsletters",
                    category: "People",
                    id: "people_interest_newsletters",
                },
                {
                    text: "Interest Schools",
                    category: "People",
                    id: "people_interest_schools",
                },

                {text: "Company", category: "Company", id: "company"},
                {text: "Jobs", category: "Company", id: "company_jobs"},
                {text: "People", category: "Company", id: "company_people"},
                {text: "Post", category: "Company", id: "company_post"},
                {text: "Group", category: "Group", id: "group"},
                {
                    text: "Information",
                    category: "Group",
                    id: "group_information",
                },
                {
                    text: "Jobs",
                    category: "Other",
                    id: "jobs",
                },
                {
                    text: "Post Comments",
                    category: "Other",
                    id: "post_comments",
                },
            ],
        };
    },
    provide: {
        multiselect: [CheckBoxSelection],
    },
};
</script>
