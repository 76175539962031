<script>
import DetailsComponent from "@/components/scraper/linkedin/results/utils/details_component.vue";

export default {
    name: "Experience",
    components: {DetailsComponent},
    props: {
        experiences: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            showExperience: false,
        };
    },
};

</script>

<template>
    <details-component header="Experience" style="min-width: 100%">
        <div v-for="(experience, index) in experiences" :key="index" style="padding: 24px">
            <hr v-if="index !== 0 "/>
            <b-row>
                <b-col cols="2">
                    <b-img
                        :src="experience.company.logo_url"
                        fluid
                        rounded
                    ></b-img>
                </b-col>
                <b-col cols="10">
                    <b-link
                        :href="experience.company.url"
                        target="_blank"
                        style="color: black"
                        ci
                    >
                        <h5>
                            {{ experience.company.name }}
                        </h5>
                    </b-link>
                    <section v-for="(position, i) in experience.positions" :key="i">

                        <hr v-if="i !== 0"/>
                        <h6>
                            {{ position.role }}
                        </h6>
                        <p>
                            <template v-if="position.start_date.month">
                                {{ position.start_date.month }} <strong>/</strong>
                            </template>
                            {{ position.start_date.year }} <strong>-</strong>
                            <template v-if="position.end_date.month || position.end_date.year">
                                <template v-if="position.end_date.month">
                                    {{ position.end_date.month }} <strong>/</strong>
                                </template>
                                {{ position.end_date.year }}
                            </template>
                            <template v-else>
                                Present
                            </template>
                            • {{ position.duration.years }} yrs {{ position.duration.months }} mos
                            <br/>
                            {{ position.location }}
                        </p>
                    </section>
                </b-col>
            </b-row>
        </div>

    </details-component>
</template>

<style scoped>

</style>
