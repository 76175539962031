<template>
    <div class="modal fade modal-nested" v-if="visible" :class="{ show: visible }" :id="id" role="dialog" aria-labelledby="modalLabel" :aria-hidden="!visible">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">{{ title }}</h4>
                    <button
                        type="button"
                        class="close"
                        @click="handleClose"
                    >
                        &times;
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="container-fluid">
                        <slot></slot>
                    </div>
                </div>
                <div class="modal-footer" v-if="$slots.footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
        <div class="modal-backdrop" @click="handleClose" v-if="visible"></div>

    </div>
</template>

<script>
export default {
    name: "Modal",
    props: {
        title: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleClose() {
            this.$emit('update:visible', false);
        },
    },
};
</script>

<style>
.modal .fade .modal-nested {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1150 !important;
    overflow: hidden;
}
.modal-dialog .modal-dialog-centered {
    z-index: 1150 !important;
}

.modal-dialog {
    min-width: 600px;
    max-width: 1000px;
    margin: 30px auto;
}

.modal-content {
    background-color: #fff;
    border-radius: 0.3rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;

}

.modal-header {
    background: #0d0d48;
    color: #ffffff;
    border-bottom: 1px solid #ddd;
}

.modal-title {
    margin: 0;
}

.modal-nested .modal-backdrop {
    z-index: 140 !important;
}

.close {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: bold;
    border: none;
    background: transparent;
}

.modal-body {
    padding: 15px;
    min-height: 40vh;
    max-height: 75vh;
    overflow: auto;
}

.show {
    display: block;
}

</style>
