<template>
    <div>
        <b-link @click="showDetails = true">
            <i class="fa fa-info"></i>
            Contact Info
        </b-link>
        <Modal id="contact_info" title="Contact Info" :visible.sync="showDetails" @close="showDetails = false">
            <div class="content">
                    <b-row>
                        <b-col cols="2" class="icon-col">
                            <i class="fa fa-linkedin icon"></i>
                        </b-col>
                        <b-col cols="10">
                            <h6>
                                <strong>
                                    {{ contactInfo.first_name }}'s Profile
                                </strong>
                            </h6>
                            <b-link :href="personalProfile" target="_blank">
                                {{ displayProfile }}
                            </b-link>
                            <hr/>
                        </b-col>
                    </b-row>
                    <b-row v-if="contactInfo.websites">
                        <b-col cols="2" class="icon-col">
                            <i class="fa fa-link icon" style="font-size: 24px"></i>
                        </b-col>
                        <b-col cols="10">
                            <h6>
                                <strong>
                                    Websites
                                </strong>
                            </h6>
                            <div v-for="(website, index) in contactInfo.websites" :key="index">
                                <hr v-if="index !== 0"/>
                                <b-link :href="website.url" target="_blank">
                                    {{ displayWebsite(website) }}
                                </b-link>
                            </div>
                            <hr/>
                        </b-col>
                    </b-row>
                    <b-row v-if="contactInfo.email">
                        <b-col cols="2" class="icon-col">
                            <i class="fa fa-envelope icon"></i>
                        </b-col>
                        <b-col cols="10">
                            <h6>
                                <strong>
                                    Email
                                </strong>
                            </h6>
                            <b-link :href="'mailto:' + contactInfo.email">
                                {{ contactInfo.email }}
                            </b-link>
                            <hr/>
                        </b-col>
                    </b-row>
                    <b-row v-if="contactInfo.birthday" >
                        <b-col cols="2" class="icon-col">
                            <i class="fa fa-birthday-cake icon"></i>
                        </b-col>
                        <b-col>
                            <h6>
                                <strong>
                                    Birthday
                                </strong>
                            </h6>
                            {{ contactInfo.birthday }}
                        </b-col>
                    </b-row>
            </div>
        </Modal>
    </div>
</template>
<script>
import Modal from "@/components/scraper/linkedin/results/utils/modal.vue";

export default {
    name: "ContactInfo",
    components: {Modal},
    computed: {
        personalProfile() {
            return `https://www.linkedin.com/in/${this.contactInfo.user}`;
        },
        displayProfile() {
            return `linkedin.com/in/${this.contactInfo.user}`;
        },
    },
    methods: {
        displayWebsite(website) {
            return `${website.url} (${website.category})`;
        }
    },
    props: {
        contactInfo: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showDetails: false,
        };
    },
};
</script>

<style scoped>
.content {
    margin: 1em;
}

.icon {
    font-size: 2em;
}

.fa-linkedin {
    color: #FFFFFF;
    background-color: #6a6a6a;
    padding: 8px;
    border-radius: 4px;
    width: 40px;
    height: 40px;
}

.fa-link {
    transform: scaleX(-1);
}

.icon-col {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
</style>
