<template>
    <b-card :no-body="!showAbout" style="min-width: 100%">
        <template #header>
            <b-row>
                <b-col cols="10" class="d-flex align-items-center">
                    <h6 class="mb-0">About</h6>
                </b-col>
                <b-col cols="2" class="d-flex justify-content-end">
                    <b-button
                        variant="outline-dark"
                        @click="showAbout = !showAbout"
                    >
                        <i
                            v-if="showAbout"
                            class="fa fa-chevron-up"
                        ></i>
                        <i
                            v-else
                            class="fa fa-chevron-down"
                        ></i>
                    </b-button>
                </b-col>
            </b-row>
        </template>
        <b-list-group flush v-if="showAbout">
            <h6>{{ about.about }}</h6>

            <b-list-group-item v-for="(feature, index) in about.featured" :key="index">
                <b-card-text>
                    <h6><strong>{{ feature.type }}</strong></h6>
                    <p>{{ feature.text }}</p>
                </b-card-text>
                <b-img :src="feature.attachments.image" fluid></b-img>
                <b-card-footer>
                    <b-col>
                        <b-row>
                            <template
                                v-if="
                            feature.reactions && feature.reactions.length > 0
                        "
                            >
                                <b-button
                                    variant="outline-primary"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Like"
                                    class="m-1"
                                >
                                    {{ getReactionCount(feature.reactions, "LIKE") }}
                                    <i class="fa fa-thumbs-up"></i>
                                </b-button>
                                <b-button
                                    variant="outline-success"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Praise"
                                    class="m-1"
                                >
                                    {{ getReactionCount(feature.reactions, "PRAISE") }}
                                    <i class="fa fa-sign-language"></i>
                                </b-button>
                                <b-button
                                    variant="outline-danger"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Empathy"
                                    class="m-1"
                                >
                                    {{ getReactionCount(feature.reactions, "EMPATHY") }}
                                    <i class="fa fa-heart"></i>
                                </b-button>
                                <b-button
                                    variant="outline-warning"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Interest"
                                    class="m-1"
                                >
                                    {{ getReactionCount(feature.reactions, "INTEREST") }}
                                    <i class="fa fa-lightbulb-o"></i>
                                </b-button>
                                <b-button
                                    variant="outline-info"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Appreciation"
                                    class="m-1"
                                >
                                    {{ getReactionCount(feature.reactions, "APPRECIATION") }}
                                    <i class="fa fa-smile-o"></i>
                                </b-button>
                            </template>
                            <template v-else>
                                <p>No reactions available</p>
                            </template>
                        </b-row>
                        <b-row>
                            <b-button
                                variant="outline-dark"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Shares"
                                class="m-1"
                                v-if="feature.shares !== undefined"
                            >
                                {{ feature.shares }}
                                <i class="fa fa-share"></i>
                            </b-button>
                            <b-button variant="outline-dark" v-else>No shares</b-button>
                            <b-button
                                variant="outline-dark"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Comments"
                                class="m-1"
                                v-if="feature.comments !== undefined"
                            >
                                {{ feature.comments }}
                                <i class="fa fa-comment"></i>
                            </b-button>
                            <b-button variant="outline-dark" v-else
                            >No comments
                            </b-button
                            >
                            <b-button
                                variant="outline-dark"
                                :href="feature.url"
                                target="_blank"
                                class="m-1"
                            >
                                <i class="fa fa-linkedin"></i>
                                View on LinkedIn
                            </b-button>
                        </b-row>
                    </b-col>
                </b-card-footer>
            </b-list-group-item>
        </b-list-group>
    </b-card>
</template>
<script>
export default {
    name: "About",
    props: {
        about: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showAbout: false,
        };
    },
    methods: {
        getReactionCount(reactions, type) {
            const reaction =
                reactions &&
                reactions.find((r) => r.reaction_type === type);
            return reaction ? reaction.count : 0;
        },
        beforeEnter(el) {
            el.style.opacity = 0;
            el.style.maxHeight = '0';
        },
        enter(el) {
            el.offsetHeight; // trigger reflow
            el.style.transition = 'max-height 0.5s ease-out, opacity 0.5s ease-out';
            el.style.opacity = 1;
            el.style.maxHeight = el.scrollHeight + 'px';
        },
        leave(el) {
            el.style.transition = 'max-height 0.5s ease-in, opacity 0.5s ease-in';
            el.style.opacity = 0;
            el.style.maxHeight = 0;
        }
    },
};
</script>
<style scoped>
</style>
