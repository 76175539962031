<template>
    <details-component header="Education">
        <div v-for="(education, index) in educations" :key="index" style="padding: 24px">
            <hr v-if="index !== 0"/>
            <b-row>
                <b-col cols="2" v-if="education.institute.logo">
                    <b-img
                        :src="education.institute.logo"
                        fluid
                        rounded
                    ></b-img>
                </b-col>
                <b-col :cols="education.institute.logo ? '10' : '12'">
                    <b-link
                        :href="education.institute.url"
                        target="_blank"
                        style="color: black"
                        ci
                    >
                        <h5>
                            {{ education.institute.name }}
                        </h5>
                    </b-link>
                    <section>
                        <h6 v-if="education.degree_program">
                            {{ education.degree_program }}
                        </h6>
                        <h6 v-if="education.description">
                            {{ education.description }}
                        </h6>
                        <h6 v-if="education.additional_info">
                            {{ education.additional_info }}
                        </h6>

                        <p>
                            <template v-if="education.start_date && education.end_date">
                                <template v-if="education.start_date.month">
                                    {{ education.start_date.month }} <strong>/</strong>
                                </template>
                                {{ education.start_date.year }} <strong>-</strong>
                                <template v-if=" education.end_date.month || education.end_date.year">
                                    <template v-if="education.end_date.month">
                                        {{ education.end_date.month }} <strong>/</strong>
                                    </template>
                                    {{ education.end_date.year }}
                                </template>
                                <template v-else>
                                    Present
                                </template>
                                • {{ education.duration.years }} yrs {{ education.duration.months }} mos
                            </template>
                            <template v-else>
                                No date information
                            </template>
                            <br/>
                            {{ education.location }}
                        </p>
                    </section>
                </b-col>
            </b-row>
        </div>

    </details-component>
</template>

<script>
import DetailsComponent from "@/components/scraper/linkedin/results/utils/details_component.vue";

export default {
    name: "Education",
    components: {DetailsComponent},
    props: {
        educations: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>
