<script>
import Overview from "@/components/scraper/linkedin/results/people/overview.vue";
import About from "@/components/scraper/linkedin/results/people/about.vue";
import Experience from "@/components/scraper/linkedin/results/people/experience.vue";
import Skills from "@/components/scraper/linkedin/results/people/skills.vue";
import Education from "@/components/scraper/linkedin/results/people/education.vue";
import Activities from "@/components/scraper/linkedin/results/people/activities.vue";
import PeopleAlsoViewed from "@/components/scraper/linkedin/results/people/people_also_viewed.vue";

export default {
    name: "People",
    components: {PeopleAlsoViewed, Activities, Education, Skills, Experience, About, Overview},
    props: {
        row: {
            type: Object,
            required: true,
        },
    },
};
</script>

<template>
    <b-container>
        <b-row v-if="row.overview">
            <Overview :overview="row.overview[0]" :contact-info="row.contact_info[0]"/>
        </b-row>
        <b-row v-if="row.about" class="data-point">
            <About :about="row.about[0]"/>
        </b-row>
        <b-row v-if="row.experience" class="data-point">
            <Experience :experiences="row.experience[0].experience"/>
        </b-row>
        <b-row v-if="row.skills" class="data-point">
            <Skills :skills="row.skills[0].skills"/>
        </b-row>
        <b-row v-if="row.education" class="data-point">
            <Education :educations="row.education[0].education"/>
        </b-row>
        <b-row v-if="row.activity" class="data-point">
            <Activities :activities="row.activity"/>
        </b-row>
        <b-row v-if="row.people_also_viewed" class="data-point">
            <PeopleAlsoViewed :people="row.people_also_viewed[0].people_also_viewed"/>
        </b-row>
    </b-container>
</template>

<style scoped>
.data-point {
    margin-top: 12px;
}
</style>
