<template>
    <b-row>
        <b-col v-for="post in displayedPosts" :key="post._id" cols="6" class="mb-2 d-flex align-content-center">
            <b-card>
                <b-card-text>
                    <p v-if="post.caption.text">
                        {{ post.showFullCaption ? post.caption.text : truncatedCaption(post.caption.text) }}
                        <span v-if="isLongCaption(post.caption.text)" @click="toggleCaption(post)" class="toggle-text">
                            {{ post.showFullCaption ? ' Show Less' : ' Show More' }}
                        </span>
                    </p>
                    <template v-if="post.product_type === productType.FEED">
                        <div>
                            <ImageLoader
                                :image-url="post.image_versions2.candidates[0].url"
                                :alt-text="'post_image_' + post.id"
                                class="img-thumbnail"
                            />
                        </div>
                    </template>
                    <template v-else-if="post.product_type === productType.CAROUSEL_CONTAINER">
                        <div>
                            <CarrouselContent :carrousel-media="post.carousel_media" />
                        </div>
                    </template>
                    <template v-else-if="post.product_type === productType.CLIPS">
                        <div>
                            <iframe width="auto" height="450px" :src="post.video_versions[0].url" allowfullscreen></iframe>
                        </div>
                    </template>
                    <b class="mr-4" v-if="post.product_type === productType.CLIPS">
                        <i class="fa fa-play mr-2"></i> {{ formatNumber(post.play_count) }}
                    </b>
                    <b class="mr-4">
                        <i class="fa fa-heart mr-2"></i> {{ formatNumber(post.like_count) }}
                    </b>
                    <b class="mr-4">
                        <i class="fa fa-comment mr-2"></i> {{ formatNumber(post.comment_count) }}
                    </b>
                </b-card-text>
            </b-card>
        </b-col>
        <b-col cols="12">
            <infinite-loading @infinite="loadMorePosts" ref="infiniteLoading"></infinite-loading>
        </b-col>
    </b-row>
</template>

<script>
import ImageLoader from "@/components/instagramIntegration/imageLoader.vue";
import CarrouselContent from "@/components/instagramIntegration/carrousel_content.vue";
import InfiniteLoading from 'vue-infinite-loading';
import axios from 'axios';

export default {
    name: 'FeedComponent',
    components: { ImageLoader, CarrouselContent, InfiniteLoading },
    props : {
        config_id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            maxLength: 100,
            productType: {
                'FEED': 'feed',
                'CAROUSEL_CONTAINER': 'carousel_container',
                'CLIPS': 'clips'
            },
            displayedPosts: [],
            currentPage: 1,
            postsPerPage: 12,
            loading: false,
            totalPosts: 0
        };
    },
    mounted() {
        this.loadPosts();
    },
    methods: {
        truncatedCaption(caption) {
            return caption.length > this.maxLength
                ? caption.slice(0, this.maxLength) + '...'
                : caption;
        },
        isLongCaption(caption) {
            return caption.length > this.maxLength;
        },
        toggleCaption(post) {
            console.log(post);
            post.showFullCaption = !post.showFullCaption;
        },

        async loadPosts() {
            try {
                const response = await axios.get(`${this.$root.serverUrl}/admin/instagram/social_results?config_id=${this.config_id}&$top=${this.postsPerPage}&$skip=0`, {
                    'api-key': this.$root.apiKey
                });
                const { Items, Count } = response.data;
                this.displayedPosts = Items.map(post => ({
                    ...post,
                    showFullCaption: false
                }));
                this.totalPosts = Count;
            } catch (error) {
                console.error(error);
            }
        },async loadMorePosts($state) {
            if (this.loading) return;

            try {
                this.loading = true;
                const response = await axios.get(`${this.$root.serverUrl}/admin/instagram/social_results`, {
                    params: {
                        config_id: this.config_id,
                        $top: this.postsPerPage,
                        $skip: this.currentPage * this.postsPerPage
                    },
                    headers: {
                        'api-key': this.$root.apiKey
                    }
                });

                const { Items } = response.data;

                if (Items.length > 0) {

                    this.displayedPosts.push(...Items.map(post => ({
                        ...post,
                        showFullCaption: false
                    })))
                    this.currentPage++;
                    $state.loaded();
                } else {
                    $state.complete();
                }
            } catch (error) {
                console.error(error);
                $state.error();
            } finally {
                this.loading = false;
            }
        }
    ,

        formatNumber(num) {
            if (num == null) {
                return 'N/A';
            }
            if (num >= 1e9) {
                return (num / 1e9).toFixed(2) + 'B';
            } else if (num >= 1e6) {
                return (num / 1e6).toFixed(2) + 'M';
            } else if (num >= 1e3) {
                return (num / 1e3).toFixed(2) + 'K';
            } else {
                return num.toString();
            }
        }
    }
};
</script>

<style scoped>
.toggle-text {
    color: blue;
    cursor: pointer;
}
</style>
