<template>
    <b-card>
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <strong>First Name:</strong> {{ contact.first_name }}
            </li>
            <li class="list-group-item">
                <strong>Last Name:</strong> {{ contact.last_name }}
            </li>
            <li class="list-group-item">
                <strong>Email:</strong>
                {{ contact.email ? contact.email : "N/A" }}
            </li>
            <li class="list-group-item">
                <strong>Public Identifier:</strong>
                {{ contact.public_identifier }}
            </li>
            <li class="list-group-item">
                <strong>Websites:</strong>
                <ul>
                    <li
                        v-for="(website, index) in contact.websites"
                        :key="index"
                    >
                        {{ website.category }}:
                        <a :href="website.url" target="_blank">{{
                            website.url
                        }}</a>
                    </li>
                </ul>
            </li>
            <li class="list-group-item">
                <strong>Twitter Handles:</strong>
                <ul>
                    <li v-if="contact.twitter_handles.length === 0">N/A</li>
                    <li
                        v-for="(handle, index) in contact.twitter_handles"
                        :key="index"
                    >
                        {{ handle }}
                    </li>
                </ul>
            </li>
            <li class="list-group-item">
                <strong>Birthday:</strong>
                {{ contact.birthday ? contact.birthday : "N/A" }}
            </li>
            <li class="list-group-item">
                <strong>LinkedIn URL:</strong>
                <a :href="contact.url" target="_blank">{{ contact.url }}</a>
            </li>
        </ul>
    </b-card>
</template>

<script>
export default {
    name: "ContactInfo",
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>
.list-group-item {
    display: flex;
    justify-content: space-between;
}
</style>
