<template>
    <!-- The Modal -->
    <div class="modal fade" id="configResultModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Social Results</h4>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        @click="$emit('close')"
                    >
                        &times;
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row justify-content-center">
                            <div class="col-md-8 col-sm-12">
                                <ul class="nav nav-tabs" v-if="item.platform === 'linkedin'">
                                    <li
                                        v-for="(category, index) in item.categories"
                                        class="nav-item"
                                        :key="index"
                                    >
                                        <a
                                            :class="`nav-link ${
                                        active === category ? 'active' : ''
                                        }`"
                                            :href="`#${category}`"
                                            @click="change_category(category)"
                                            data-toggle="tab"
                                        >
                                            {{ category }}
                                        </a>
                                    </li>
                                </ul>
                                <ul class="nav nav-tabs" v-else>
                                    <li
                                        v-for="(point, index) in item.points"
                                        class="nav-item"
                                        :key="index"
                                    >
                                        <a
                                            :class="`nav-link ${
                                                active === point ? 'active' : ''
                                            }`"
                                            :href="`#${point}`"
                                            @click="change(point)"
                                            data-toggle="tab"
                                        >{{ point }}</a
                                        >
                                    </li>
                                    <li v-if="item.verified" class="nav-item">
                                        <a
                                            :class="`nav-link ${
                                                active === 'results'
                                                    ? 'active'
                                                    : ''
                                            }`"
                                            href="#results"
                                            @click="change('results')"
                                            data-toggle="tab"
                                        >Results</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="tab-content mt-4">
                            <div
                                :class="`tab-pane fade ${
                                    active === 'results' ? 'show active' : ''
                                }`"
                                id="results"
                            >
                                <div
                                    v-for="(row, idx) in data"
                                    :key="idx"
                                    class="shadow-sm mb-4"
                                >
                                    <div v-if="row.type === 'tweet'">
                                        <div
                                            class="d-flex p-2"
                                            style="gap: 15px"
                                        >
                                            <div>
                                                <img
                                                    :src="
                                                        row.user
                                                            .profile_photo_url
                                                    "
                                                />
                                            </div>
                                            <div>
                                                <div>
                                                    <strong
                                                        href=""
                                                        v-if="row.user"
                                                    >{{
                                                            row.user.name
                                                        }}</strong
                                                    >
                                                    <span v-if="row.created"
                                                    ><small>{{
                                                            toDate(row.created)
                                                        }}</small></span
                                                    >
                                                </div>
                                                <div>{{ row.text }}</div>
                                                <div
                                                    v-if="
                                                        row.media &&
                                                        row.media.length
                                                    "
                                                >
                                                    <a
                                                        class="mr-2"
                                                        target="_blank"
                                                        :href="link"
                                                        v-for="(
                                                            link, index
                                                        ) in row.media"
                                                        :key="index"
                                                    >{{ link }}</a
                                                    >
                                                </div>
                                                <a
                                                    href="#"
                                                    @click="
                                                        row.showRetweets =
                                                            !row.showRetweets;
                                                        $forceUpdate();
                                                    "
                                                >Retweets:
                                                    {{
                                                        row.retweets
                                                            ? row.retweets
                                                                .length
                                                            : 0
                                                    }}</a
                                                >

                                                <div v-if="row.showRetweets">
                                                    <div
                                                        v-for="(
                                                            user, ridx
                                                        ) in row.retweets"
                                                        :key="ridx"
                                                    >
                                                        <div
                                                            class="d-flex p-2"
                                                            style="gap: 15px"
                                                        >
                                                            <div>
                                                                <img
                                                                    width="50px"
                                                                    :src="
                                                                        user.profile_photo_url
                                                                    "
                                                                />
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    <a
                                                                        target="_blank"
                                                                        href=""
                                                                    >{{
                                                                            user.name
                                                                        }}</a
                                                                    >
                                                                    <span
                                                                    ><small
                                                                    >{{
                                                                            toDate(
                                                                                user.created
                                                                            )
                                                                        }}</small
                                                                    ></span
                                                                    >
                                                                </div>
                                                                <div>
                                                                    {{
                                                                        user.screen_name
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="row.type === 'post'">
                                        <div
                                            class="d-flex p-2"
                                            style="gap: 15px"
                                        >
                                            <div>
                                                <div>
                                                    <strong
                                                        href=""
                                                        v-if="row.user"
                                                    >{{
                                                            row.user.name
                                                        }}</strong
                                                    >
                                                    <span v-if="row.created"
                                                    ><small>{{
                                                            toDate(row.created)
                                                        }}</small></span
                                                    >
                                                </div>
                                                <div>{{ row.message }}</div>
                                                <div v-if="row.content">
                                                    <img
                                                        v-if="
                                                            row.content
                                                                .photo_url
                                                        "
                                                        width="350px"
                                                        :src="
                                                            row.content
                                                                .photo_url
                                                        "
                                                    />
                                                    <img
                                                        v-if="
                                                            row.content
                                                                .share_url
                                                        "
                                                        width="350px"
                                                        :src="
                                                            row.content
                                                                .share_url
                                                        "
                                                    />
                                                    <img
                                                        v-if="
                                                            row.content
                                                                .thumbnail
                                                        "
                                                        width="350px"
                                                        :src="
                                                            row.content
                                                                .thumbnail
                                                        "
                                                    />
                                                    <video
                                                        v-if="
                                                            row.content
                                                                .video_url
                                                        "
                                                        width="350px"
                                                        :src="
                                                            row.content
                                                                .video_url
                                                        "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-for="(category, index) in item.categories"
                            :key="index"
                            :class="`tab-pane fade ${
                                active === category ? 'show active' : ''
                            }`"
                                 :id="category">
                                <div
                                    v-for="(row, idx) in data"
                                    :key="idx"
                                    class="shadow-sm mb-4">
                                    <people :row="row"/>
                                </div>

                            </div>
                            <div
                                v-for="(point, index) in item.points"
                                :class="`tab-pane fade ${
                                    active === point ? 'show active' : ''
                                }`"
                                :id="point"
                                :key="index"
                            >
                                <div
                                    :class="`${
                                        ['Videos', 'Photos'].includes(point)
                                            ? 'row'
                                            : ''
                                    }`"
                                >
                                    <div
                                        v-for="(row, idx) in data"
                                        :key="idx"
                                        :class="`${
                                            ['Videos', 'Photos'].includes(point)
                                                ? 'col-6 col-md-4 col-lg-3 col-xl-2 d-flex'
                                                : 'shadow-sm mb-4'
                                        }`"
                                    >
                                        <div
                                            v-if="
                                                row.type === 'profile' &&
                                                row.platform === 'instagram'
                                            "
                                        >
                                            <b-row class="m-2">
                                                <b-col cols="2">
                                                    <ImageLoader
                                                        :image-url="
                                                            row.profile_pic_url ||
                                                            'default_pic_url'
                                                        "
                                                        :alt-text="
                                                            'profile_pic_' +
                                                            row.username
                                                        "
                                                        class="img-thumbnail"
                                                    />
                                                </b-col>
                                                <b-col cols="8">
                                                    <b-row>
                                                        <b-col>
                                                            <b-row>
                                                                <b-col>
                                                                    <b-row
                                                                        class="ml-1"
                                                                    >
                                                                        <h4>
                                                                            {{
                                                                                row.username
                                                                            }}
                                                                        </h4>
                                                                        <span
                                                                            v-if="
                                                                                row.is_verified
                                                                            "
                                                                            class="ml-2 circle"
                                                                        >
                                                                            <i
                                                                                class="fa fa-check"
                                                                            ></i>
                                                                        </span>
                                                                    </b-row>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col
                                                                ><b>{{
                                                                        formatNumber(
                                                                            row.media_count
                                                                        )
                                                                    }}</b>
                                                                    posts
                                                                </b-col
                                                                >
                                                                <b-col
                                                                ><b>{{
                                                                        formatNumber(
                                                                            row.follower_count
                                                                        )
                                                                    }}</b>
                                                                    followers
                                                                </b-col
                                                                >
                                                                <b-col
                                                                ><b>{{
                                                                        formatNumber(
                                                                            row.following_count
                                                                        )
                                                                    }}</b>
                                                                    following
                                                                </b-col
                                                                >
                                                            </b-row>
                                                            <b-row>
                                                                <b-col
                                                                    cols="12"
                                                                >
                                                                    <b>{{
                                                                            row.full_name ||
                                                                            "not name found"
                                                                        }}</b>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col
                                                                    cols="12"
                                                                >
                                                                    <b>{{
                                                                            row.biography ||
                                                                            "not biography found"
                                                                        }}</b>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col
                                                                    cols="12"
                                                                >
                                                                    <p>
                                                                        Category:
                                                                        {{
                                                                            row.category ||
                                                                            "not category found"
                                                                        }}
                                                                    </p>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row
                                                                v-if="
                                                                    row.address_street &&
                                                                    row.city_name
                                                                "
                                                            >
                                                                <b-col
                                                                    cols="12"
                                                                >
                                                                    <p>
                                                                        {{
                                                                            row.address_street
                                                                        }},
                                                                        {{
                                                                            row.city_name
                                                                        }}
                                                                    </p>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row v-else>
                                                                <b-col
                                                                    cols="12"
                                                                >
                                                                    <p>
                                                                        not
                                                                        address
                                                                        found
                                                                    </p>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col
                                                                    cols="12"
                                                                    v-if="
                                                                        row.external_url
                                                                    "
                                                                >
                                                                    <i
                                                                        class="fa fa-link mr-2"
                                                                    ></i
                                                                    ><b
                                                                ><a
                                                                    :href="
                                                                                row.external_url
                                                                            "
                                                                >{{
                                                                        row.external_url
                                                                    }}</a
                                                                ></b
                                                                >
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col
                                                                    cols="12"
                                                                    v-if="
                                                                        row.public_email
                                                                    "
                                                                >
                                                                    <i
                                                                        class="fa fa-envelope mr-2"
                                                                    ></i>
                                                                    <b
                                                                    ><a
                                                                        :href="
                                                                                'mailto:' +
                                                                                row.public_email
                                                                            "
                                                                    >{{
                                                                            row.public_email
                                                                        }}</a
                                                                    ></b
                                                                    >
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col
                                                                    cols="12"
                                                                    v-if="
                                                                        row.contact_phone_number
                                                                    "
                                                                >
                                                                    <i
                                                                        class="fa fa-phone mr-2"
                                                                    ></i>
                                                                    <b
                                                                    ><a
                                                                        :href="
                                                                                'tel:' +
                                                                                row.contact_phone_number
                                                                            "
                                                                    >{{
                                                                            row.contact_phone_number
                                                                        }}</a
                                                                    ></b
                                                                    >
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col
                                                                    cols="12"
                                                                >
                                                                    <b>{{
                                                                            row.business_email ||
                                                                            "not business email found"
                                                                        }}</b>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <div
                                                                    v-if="
                                                                        !row.verified
                                                                    "
                                                                >
                                                                    <button
                                                                        class="btn btn-sm btn-primary"
                                                                        @click="
                                                                            verify(
                                                                                row
                                                                            )
                                                                        "
                                                                    >
                                                                        Verify
                                                                    </button>
                                                                </div>
                                                                <div
                                                                    v-if="
                                                                        row.verified
                                                                    "
                                                                >
                                                                    <i
                                                                        class="fa fa-check text-success"
                                                                    ></i>
                                                                    <span
                                                                        class="text-success"
                                                                    >Verified</span
                                                                    >
                                                                </div>
                                                            </b-row>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <div
                                            v-if="
                                                row.type === 'post' &&
                                                row.platform === 'instagram'
                                            "
                                        >
                                            <feed-component
                                                :config_id="item._id"
                                            />
                                        </div>
                                        <div v-if="row.type === 'tweet'">
                                            <div
                                                class="d-flex p-2"
                                                style="gap: 15px"
                                            >
                                                <div>
                                                    <img
                                                        :src="
                                                            row.user
                                                                .profile_photo_url
                                                        "
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        <strong
                                                            href=""
                                                            v-if="row.user"
                                                        >{{
                                                                row.user.name
                                                            }}</strong
                                                        >
                                                        <span v-if="row.created"
                                                        ><small>{{
                                                                toDate(
                                                                    row.created
                                                                )
                                                            }}</small></span
                                                        >
                                                    </div>
                                                    <div>{{ row.text }}</div>
                                                    <div
                                                        v-if="
                                                            row.media &&
                                                            row.media.length
                                                        "
                                                    >
                                                        <a
                                                            class="mr-2"
                                                            target="_blank"
                                                            :href="link"
                                                            v-for="(
                                                                link, index
                                                            ) in row.media"
                                                            :key="index"
                                                        >{{ link }}</a
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                row.type === 'post' &&
                                                row.search_type == 'browser'
                                            "
                                        >
                                            <div
                                                class="d-flex p-2"
                                                style="gap: 15px"
                                            >
                                                <div>
                                                    <img
                                                        :src="
                                                            row.user
                                                                .profile_photo_url
                                                        "
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        <strong
                                                            href=""
                                                            v-if="row.user"
                                                        >{{
                                                                row.user.name
                                                            }}</strong
                                                        >
                                                        <span v-if="row.created"
                                                        ><small>{{
                                                                toDate(
                                                                    row.created *
                                                                    1000
                                                                )
                                                            }}</small></span
                                                        >
                                                    </div>
                                                    <div>{{ row.message }}</div>
                                                    <div v-if="row.content">
                                                        <img
                                                            width="300px"
                                                            v-if="
                                                                row.content
                                                                    .type ===
                                                                'Photo'
                                                            "
                                                            :src="
                                                                row.content
                                                                    .photo_url
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                row.type === 'post' &&
                                                row.search_type == 'api'
                                            "
                                        >
                                            <div
                                                class="d-flex p-2"
                                                style="gap: 15px"
                                            >
                                                <div
                                                    v-if="
                                                        row.actors &&
                                                        row.actors.length
                                                    "
                                                >
                                                    <img
                                                        width="100px"
                                                        :src="
                                                            row.actors[0]
                                                                .profile_picture
                                                        "
                                                    />
                                                </div>
                                                <div>
                                                    <div
                                                        v-if="
                                                            row.actors &&
                                                            row.actors.length
                                                        "
                                                    >
                                                        <a
                                                            :href="
                                                                row.actors[0]
                                                                    .url
                                                            "
                                                            v-if="
                                                                row.actors[0]
                                                                    .name
                                                            "
                                                        >{{
                                                                row.actors[0]
                                                                    .name
                                                            }}</a
                                                        >
                                                        <span v-if="row.created"
                                                        ><small>{{
                                                                toDate(
                                                                    row.creation_time *
                                                                    1000
                                                                )
                                                            }}</small></span
                                                        >
                                                    </div>
                                                    <div>{{ row.message }}</div>
                                                    <div
                                                        v-if="
                                                            row.attachments &&
                                                            row.attachments
                                                                .length
                                                        "
                                                    >
                                                        <img
                                                            width="300px"
                                                            v-if="
                                                                row
                                                                    .attachments[0]
                                                                    .preview_image
                                                            "
                                                            :src="
                                                                row
                                                                    .attachments[0]
                                                                    .preview_image
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="row.type === 'list'">
                                            <div
                                                class="d-flex p-2"
                                                style="gap: 15px"
                                            >
                                                <div>
                                                    <img
                                                        width="100px"
                                                        :src="row.banner"
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        <strong>{{
                                                                row.name
                                                            }}</strong>
                                                    </div>
                                                    <div>
                                                        {{ row.description }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                row.type === 'event' &&
                                                row.search_type === 'api'
                                            "
                                        >
                                            <div
                                                class="d-flex p-2"
                                                style="gap: 15px"
                                            >
                                                <div>
                                                    <img
                                                        width="100px"
                                                        :src="row.picture"
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        <a :href="row.url">{{
                                                                row.name
                                                            }}</a>
                                                    </div>
                                                    <div>
                                                        {{ row.location }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                row.type === 'user' &&
                                                row.platform === 'twitter'
                                            "
                                        >
                                            <div
                                                class="d-flex p-2"
                                                style="gap: 15px"
                                            >
                                                <div>
                                                    <img
                                                        width="100px"
                                                        :src="
                                                            row.profile_photo_url
                                                        "
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        <a
                                                            target="_blank"
                                                            href=""
                                                        >{{ row.name }}</a
                                                        >
                                                        <span
                                                        ><small>{{
                                                                toDate(
                                                                    row.created
                                                                )
                                                            }}</small></span
                                                        >
                                                    </div>
                                                    <div>
                                                        {{ row.screen_name }}
                                                    </div>

                                                    <div v-if="!item.verified">
                                                        <button
                                                            class="btn btn-sm btn-primary"
                                                            @click="verify(row)"
                                                        >
                                                            Verify
                                                        </button>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            item.verified ==
                                                            row.id
                                                        "
                                                    >
                                                        <i
                                                            class="fa fa-check text-success"
                                                        ></i>
                                                        <span
                                                            class="text-success"
                                                        >Verified</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                row.type === 'user' &&
                                                row.platform === 'facebook' &&
                                                row.search_type === 'api'
                                            "
                                        >
                                            <div
                                                class="d-flex p-2"
                                                style="gap: 15px"
                                            >
                                                <div>
                                                    <img
                                                        width="100px"
                                                        :src="row.photoUrl"
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        <a
                                                            target="_blank"
                                                            :href="row.url"
                                                        >{{ row.name }}</a
                                                        >
                                                    </div>
                                                    <div>{{ row.info }}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                row.type === 'user' &&
                                                row.platform === 'facebook' &&
                                                row.search_type === 'browser'
                                            "
                                        >
                                            <div
                                                class="d-flex p-2"
                                                style="gap: 15px"
                                            >
                                                <div>
                                                    <img
                                                        width="100px"
                                                        :src="
                                                            row.profile_picture
                                                        "
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        <a
                                                            target="_blank"
                                                            :href="
                                                                row.profile_url
                                                            "
                                                        >{{ row.name }}</a
                                                        >
                                                        <span
                                                        ><small
                                                        >verified:
                                                                {{
                                                                row.is_verified
                                                            }}}</small
                                                        ></span
                                                        >
                                                    </div>
                                                    <div>
                                                        {{ row.screen_name }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                row.type === 'group' &&
                                                row.search_type === 'api'
                                            "
                                        >
                                            <div
                                                class="d-flex p-2"
                                                style="gap: 15px"
                                            >
                                                <div>
                                                    <img
                                                        width="100px"
                                                        :src="row.photoUrl"
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        <a
                                                            target="_blank"
                                                            :href="row.url"
                                                        >{{ row.name }}</a
                                                        >
                                                    </div>
                                                    <div>
                                                        <small
                                                        >Members -
                                                            {{
                                                                row.members
                                                            }}</small
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                row.type === 'group' &&
                                                row.search_type === 'browser'
                                            "
                                        >
                                            <div
                                                class="d-flex p-2"
                                                style="gap: 15px"
                                            >
                                                <div>
                                                    <img
                                                        width="100px"
                                                        :src="row.picture"
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        <a
                                                            target="_blank"
                                                            :href="row.url"
                                                        >{{ row.name }}</a
                                                        >
                                                    </div>
                                                    <!--                                                    <div><small>Members - {{ row.members }}</small></div>-->
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="image-container"
                                            v-if="
                                                row.type === 'video' &&
                                                row.search_type === 'api'
                                            "
                                        >
                                            <a target="_blank" :href="row.url"
                                            ><img
                                                height="200"
                                                :src="row.thumbnail_url"
                                            /></a>
                                        </div>

                                        <div
                                            class="image-container"
                                            v-if="
                                                row.type === 'video' &&
                                                row.search_type === 'browser'
                                            "
                                        >
                                            <a target="_blank" :href="row.url"
                                            ><img
                                                height="200"
                                                :src="row.thumbnail"
                                            /></a>
                                        </div>

                                        <div
                                            class="image-container"
                                            v-if="
                                                row.type === 'photo' &&
                                                row.search_type === 'api'
                                            "
                                        >
                                            <a target="_blank" :href="row.url">
                                                <div
                                                    v-if="
                                                        row.attachments &&
                                                        row.attachments.length
                                                    "
                                                >
                                                    <img
                                                        height="200"
                                                        :src="
                                                            row.attachments[0]
                                                                .preview_image
                                                        "
                                                    />
                                                </div>
                                            </a>
                                        </div>

                                        <div
                                            class="image-container"
                                            v-if="
                                                row.type === 'photo' &&
                                                row.search_type === 'browser'
                                            "
                                        >
                                            <a target="_blank" :href="row.url">
                                                <div v-if="row.image">
                                                    <img
                                                        height="200"
                                                        :src="row.image"
                                                    />
                                                </div>
                                            </a>
                                        </div>

                                        <div v-if="row.contact_info">
                                            about
                                        </div>
                                        <div
                                            v-if="
                                                row.type === 'contactInfo' &&
                                                row.platform === 'linkedin'
                                            "
                                        >
                                            <contact-info :contact="row"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import ImageLoader from "@/components/instagramIntegration/imageLoader.vue";
import FeedComponent from "@/components/instagramIntegration/feed_component.vue";
import contactInfo from "@/components/scraper/linkedin/ContactInfo.vue";
import People from "@/components/scraper/linkedin/results/people/people.vue";

export default {
    name: "ResultModal",
    props: ["item"],
    // eslint-disable-next-line vue/no-unused-components
    components: {
        People,
        FeedComponent,
        ImageLoader,
        contactInfo,
    },

    data() {
        return {
            data: [],
            count: 0,
            page: 1,
            pageSize: 5,
            active: "",
        };
    },
    mounted() {
        this.active = "results";
        this.search();
    },
    computed: {
        columns() {
            return this.data.length ? Object.keys(this.data[0]) : [];
        },
    },
    methods: {
        formatNumber(num) {
            if (num == null) {
                return "N/A";
            }
            if (num >= 1e9) {
                return (num / 1e9).toFixed(2) + "B";
            } else if (num >= 1e6) {
                return (num / 1e6).toFixed(2) + "M";
            } else if (num >= 1e3) {
                return (num / 1e3).toFixed(2) + "K";
            } else {
                return num.toString();
            }
        },
        change(point) {
            this.active = point;
            this.search();
        },
        change_category(category) {
            this.active = category;
            this.search_category();
        },
        toDate(date) {
            return moment(date).format("YYYY-MM-DD hh:mm:ss");
        },
        verify(user) {
            axios
                .put(`${this.$root.serverUrl}/admin/social/${this.item._id}`, {
                    args: {verified: user.id},
                })
                .then((resp) => {
                    if (resp.data && resp.data.error) {
                        swal({
                            title: "Error",
                            text: "Unable to process your request",
                            icon: "error",
                        });
                    } else {
                        swal({
                            title: "Success",
                            text: "Account verified",
                            icon: "success",
                        });
                        this.item.verified = user.id;
                        this.$forceUpdate();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    swal({
                        title: "Error",
                        text: "Unable to process your request",
                        icon: "error",
                    });
                });
        },
        search_category(){
            let request = {
                $skip: (this.page - 1) * this.pageSize,
                $top: this.pageSize,
                filters: {category: this.active},
            }
            axios
                .get(
                    `${this.$root.serverUrl}/admin/social/results/${this.item._id}`,
                    {params: request}
                )
                .then((resp) => {
                    this.data = resp.data.Items;
                    this.count = resp.data.Count;
                })
                .catch(() => {
                    swal({
                        title: "Error",
                        text: "Unable to process your request",
                        icon: "error",
                    });
                }).finally(() => {
                    this.$root.preloader = false;
                });
        },

        search() {
            let request = {
                $skip: (this.page - 1) * this.pageSize,
                $top: this.pageSize,
                filters: {point: this.active},
            };
            axios
                .get(
                    `${this.$root.serverUrl}/admin/social/results/${this.item._id}`,
                    {params: request}
                )
                .then((resp) => {
                    this.data = resp.data.Items;
                    this.count = resp.data.Count;
                    this.$root.preloader = false;
                })
                .catch(() => {
                    swal({
                        title: "Error",
                        text: "Unable to process your request",
                        icon: "error",
                    });
                    this.$root.preloader = false;
                });
        },
    },
};
</script>

<style scoped>
.modal {
    overflow-y: auto;
    z-index: 1050 !important;
}
.modal-dialog {
    height: 900px;
    display: flex;
    max-width: 1000px;
}

.modal-header {
    background: #0d0d48;
}

.modal-title {
    color: #ffffff;
}

.close {
    color: #ffffff;
}

.modal-body {
    overflow: auto;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    gap: 10px;
    justify-content: center;
    padding: 20px;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.image-grid img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: green;
    border-radius: 50%;
}

.fa-check {
    color: white;
    font-size: 10px;
}

.toggle-text {
    color: blue;
    cursor: pointer;
}

.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: green;
    border-radius: 50%;
}

.fa-check {
    color: white;
    font-size: 10px;
}

.toggle-text {
    color: blue;
    cursor: pointer;
}

.profile-card {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    max-width: 300px;
}

.profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.company-logo {
    width: 50px;
    height: 50px;
}
</style>
