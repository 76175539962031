var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"configResultModal"}},[_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h4',{staticClass:"modal-title"},[_vm._v("Social Results")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" × ")])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8 col-sm-12"},[(_vm.item.platform === 'linkedin')?_c('ul',{staticClass:"nav nav-tabs"},_vm._l((_vm.item.categories),function(category,index){return _c('li',{key:index,staticClass:"nav-item"},[_c('a',{class:("nav-link " + (_vm.active === category ? 'active' : '')),attrs:{"href":("#" + category),"data-toggle":"tab"},on:{"click":function($event){return _vm.change_category(category)}}},[_vm._v(" "+_vm._s(category)+" ")])])}),0):_c('ul',{staticClass:"nav nav-tabs"},[_vm._l((_vm.item.points),function(point,index){return _c('li',{key:index,staticClass:"nav-item"},[_c('a',{class:("nav-link " + (_vm.active === point ? 'active' : '')),attrs:{"href":("#" + point),"data-toggle":"tab"},on:{"click":function($event){return _vm.change(point)}}},[_vm._v(_vm._s(point))])])}),(_vm.item.verified)?_c('li',{staticClass:"nav-item"},[_c('a',{class:("nav-link " + (_vm.active === 'results'
                                                ? 'active'
                                                : '')),attrs:{"href":"#results","data-toggle":"tab"},on:{"click":function($event){return _vm.change('results')}}},[_vm._v("Results")])]):_vm._e()],2)])]),_c('div',{staticClass:"tab-content mt-4"},[_c('div',{class:("tab-pane fade " + (_vm.active === 'results' ? 'show active' : '')),attrs:{"id":"results"}},_vm._l((_vm.data),function(row,idx){return _c('div',{key:idx,staticClass:"shadow-sm mb-4"},[(row.type === 'tweet')?_c('div',[_c('div',{staticClass:"d-flex p-2",staticStyle:{"gap":"15px"}},[_c('div',[_c('img',{attrs:{"src":row.user
                                                        .profile_photo_url}})]),_c('div',[_c('div',[(row.user)?_c('strong',{attrs:{"href":""}},[_vm._v(_vm._s(row.user.name))]):_vm._e(),(row.created)?_c('span',[_c('small',[_vm._v(_vm._s(_vm.toDate(row.created)))])]):_vm._e()]),_c('div',[_vm._v(_vm._s(row.text))]),(
                                                    row.media &&
                                                    row.media.length
                                                )?_c('div',_vm._l((row.media),function(link,index){return _c('a',{key:index,staticClass:"mr-2",attrs:{"target":"_blank","href":link}},[_vm._v(_vm._s(link))])}),0):_vm._e(),_c('a',{attrs:{"href":"#"},on:{"click":function($event){row.showRetweets =
                                                        !row.showRetweets;
                                                    _vm.$forceUpdate();}}},[_vm._v("Retweets: "+_vm._s(row.retweets ? row.retweets .length : 0))]),(row.showRetweets)?_c('div',_vm._l((row.retweets),function(user,ridx){return _c('div',{key:ridx},[_c('div',{staticClass:"d-flex p-2",staticStyle:{"gap":"15px"}},[_c('div',[_c('img',{attrs:{"width":"50px","src":user.profile_photo_url}})]),_c('div',[_c('div',[_c('a',{attrs:{"target":"_blank","href":""}},[_vm._v(_vm._s(user.name))]),_c('span',[_c('small',[_vm._v(_vm._s(_vm.toDate( user.created )))])])]),_c('div',[_vm._v(" "+_vm._s(user.screen_name)+" ")])])])])}),0):_vm._e()])])]):_vm._e(),(row.type === 'post')?_c('div',[_c('div',{staticClass:"d-flex p-2",staticStyle:{"gap":"15px"}},[_c('div',[_c('div',[(row.user)?_c('strong',{attrs:{"href":""}},[_vm._v(_vm._s(row.user.name))]):_vm._e(),(row.created)?_c('span',[_c('small',[_vm._v(_vm._s(_vm.toDate(row.created)))])]):_vm._e()]),_c('div',[_vm._v(_vm._s(row.message))]),(row.content)?_c('div',[(
                                                        row.content
                                                            .photo_url
                                                    )?_c('img',{attrs:{"width":"350px","src":row.content
                                                            .photo_url}}):_vm._e(),(
                                                        row.content
                                                            .share_url
                                                    )?_c('img',{attrs:{"width":"350px","src":row.content
                                                            .share_url}}):_vm._e(),(
                                                        row.content
                                                            .thumbnail
                                                    )?_c('img',{attrs:{"width":"350px","src":row.content
                                                            .thumbnail}}):_vm._e(),(
                                                        row.content
                                                            .video_url
                                                    )?_c('video',{attrs:{"width":"350px","src":row.content
                                                            .video_url}}):_vm._e()]):_vm._e()])])]):_vm._e()])}),0),_vm._l((_vm.item.categories),function(category,index){return _c('div',{key:index,class:("tab-pane fade " + (_vm.active === category ? 'show active' : '')),attrs:{"id":category}},_vm._l((_vm.data),function(row,idx){return _c('div',{key:idx,staticClass:"shadow-sm mb-4"},[_c('people',{attrs:{"row":row}})],1)}),0)}),_vm._l((_vm.item.points),function(point,index){return _c('div',{key:index,class:("tab-pane fade " + (_vm.active === point ? 'show active' : '')),attrs:{"id":point}},[_c('div',{class:("" + (['Videos', 'Photos'].includes(point)
                                        ? 'row'
                                        : ''))},_vm._l((_vm.data),function(row,idx){return _c('div',{key:idx,class:("" + (['Videos', 'Photos'].includes(point)
                                            ? 'col-6 col-md-4 col-lg-3 col-xl-2 d-flex'
                                            : 'shadow-sm mb-4'))},[(
                                            row.type === 'profile' &&
                                            row.platform === 'instagram'
                                        )?_c('div',[_c('b-row',{staticClass:"m-2"},[_c('b-col',{attrs:{"cols":"2"}},[_c('ImageLoader',{staticClass:"img-thumbnail",attrs:{"image-url":row.profile_pic_url ||
                                                        'default_pic_url',"alt-text":'profile_pic_' +
                                                        row.username}})],1),_c('b-col',{attrs:{"cols":"8"}},[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',[_c('b-row',{staticClass:"ml-1"},[_c('h4',[_vm._v(" "+_vm._s(row.username)+" ")]),(
                                                                            row.is_verified
                                                                        )?_c('span',{staticClass:"ml-2 circle"},[_c('i',{staticClass:"fa fa-check"})]):_vm._e()])],1)],1),_c('b-row',[_c('b-col',[_c('b',[_vm._v(_vm._s(_vm.formatNumber( row.media_count )))]),_vm._v(" posts ")]),_c('b-col',[_c('b',[_vm._v(_vm._s(_vm.formatNumber( row.follower_count )))]),_vm._v(" followers ")]),_c('b-col',[_c('b',[_vm._v(_vm._s(_vm.formatNumber( row.following_count )))]),_vm._v(" following ")])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b',[_vm._v(_vm._s(row.full_name || "not name found"))])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b',[_vm._v(_vm._s(row.biography || "not biography found"))])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v(" Category: "+_vm._s(row.category || "not category found")+" ")])])],1),(
                                                                row.address_street &&
                                                                row.city_name
                                                            )?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v(" "+_vm._s(row.address_street)+", "+_vm._s(row.city_name)+" ")])])],1):_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v(" not address found ")])])],1),_c('b-row',[(
                                                                    row.external_url
                                                                )?_c('b-col',{attrs:{"cols":"12"}},[_c('i',{staticClass:"fa fa-link mr-2"}),_c('b',[_c('a',{attrs:{"href":row.external_url}},[_vm._v(_vm._s(row.external_url))])])]):_vm._e()],1),_c('b-row',[(
                                                                    row.public_email
                                                                )?_c('b-col',{attrs:{"cols":"12"}},[_c('i',{staticClass:"fa fa-envelope mr-2"}),_c('b',[_c('a',{attrs:{"href":'mailto:' +
                                                                            row.public_email}},[_vm._v(_vm._s(row.public_email))])])]):_vm._e()],1),_c('b-row',[(
                                                                    row.contact_phone_number
                                                                )?_c('b-col',{attrs:{"cols":"12"}},[_c('i',{staticClass:"fa fa-phone mr-2"}),_c('b',[_c('a',{attrs:{"href":'tel:' +
                                                                            row.contact_phone_number}},[_vm._v(_vm._s(row.contact_phone_number))])])]):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b',[_vm._v(_vm._s(row.business_email || "not business email found"))])])],1),_c('b-row',[(
                                                                    !row.verified
                                                                )?_c('div',[_c('button',{staticClass:"btn btn-sm btn-primary",on:{"click":function($event){return _vm.verify(
                                                                            row
                                                                        )}}},[_vm._v(" Verify ")])]):_vm._e(),(
                                                                    row.verified
                                                                )?_c('div',[_c('i',{staticClass:"fa fa-check text-success"}),_c('span',{staticClass:"text-success"},[_vm._v("Verified")])]):_vm._e()])],1)],1)],1)],1)],1):_vm._e(),(
                                            row.type === 'post' &&
                                            row.platform === 'instagram'
                                        )?_c('div',[_c('feed-component',{attrs:{"config_id":_vm.item._id}})],1):_vm._e(),(row.type === 'tweet')?_c('div',[_c('div',{staticClass:"d-flex p-2",staticStyle:{"gap":"15px"}},[_c('div',[_c('img',{attrs:{"src":row.user
                                                            .profile_photo_url}})]),_c('div',[_c('div',[(row.user)?_c('strong',{attrs:{"href":""}},[_vm._v(_vm._s(row.user.name))]):_vm._e(),(row.created)?_c('span',[_c('small',[_vm._v(_vm._s(_vm.toDate( row.created )))])]):_vm._e()]),_c('div',[_vm._v(_vm._s(row.text))]),(
                                                        row.media &&
                                                        row.media.length
                                                    )?_c('div',_vm._l((row.media),function(link,index){return _c('a',{key:index,staticClass:"mr-2",attrs:{"target":"_blank","href":link}},[_vm._v(_vm._s(link))])}),0):_vm._e()])])]):_vm._e(),(
                                            row.type === 'post' &&
                                            row.search_type == 'browser'
                                        )?_c('div',[_c('div',{staticClass:"d-flex p-2",staticStyle:{"gap":"15px"}},[_c('div',[_c('img',{attrs:{"src":row.user
                                                            .profile_photo_url}})]),_c('div',[_c('div',[(row.user)?_c('strong',{attrs:{"href":""}},[_vm._v(_vm._s(row.user.name))]):_vm._e(),(row.created)?_c('span',[_c('small',[_vm._v(_vm._s(_vm.toDate( row.created * 1000 )))])]):_vm._e()]),_c('div',[_vm._v(_vm._s(row.message))]),(row.content)?_c('div',[(
                                                            row.content
                                                                .type ===
                                                            'Photo'
                                                        )?_c('img',{attrs:{"width":"300px","src":row.content
                                                                .photo_url}}):_vm._e()]):_vm._e()])])]):_vm._e(),(
                                            row.type === 'post' &&
                                            row.search_type == 'api'
                                        )?_c('div',[_c('div',{staticClass:"d-flex p-2",staticStyle:{"gap":"15px"}},[(
                                                    row.actors &&
                                                    row.actors.length
                                                )?_c('div',[_c('img',{attrs:{"width":"100px","src":row.actors[0]
                                                            .profile_picture}})]):_vm._e(),_c('div',[(
                                                        row.actors &&
                                                        row.actors.length
                                                    )?_c('div',[(
                                                            row.actors[0]
                                                                .name
                                                        )?_c('a',{attrs:{"href":row.actors[0]
                                                                .url}},[_vm._v(_vm._s(row.actors[0] .name))]):_vm._e(),(row.created)?_c('span',[_c('small',[_vm._v(_vm._s(_vm.toDate( row.creation_time * 1000 )))])]):_vm._e()]):_vm._e(),_c('div',[_vm._v(_vm._s(row.message))]),(
                                                        row.attachments &&
                                                        row.attachments
                                                            .length
                                                    )?_c('div',[(
                                                            row
                                                                .attachments[0]
                                                                .preview_image
                                                        )?_c('img',{attrs:{"width":"300px","src":row
                                                                .attachments[0]
                                                                .preview_image}}):_vm._e()]):_vm._e()])])]):_vm._e(),(row.type === 'list')?_c('div',[_c('div',{staticClass:"d-flex p-2",staticStyle:{"gap":"15px"}},[_c('div',[_c('img',{attrs:{"width":"100px","src":row.banner}})]),_c('div',[_c('div',[_c('strong',[_vm._v(_vm._s(row.name))])]),_c('div',[_vm._v(" "+_vm._s(row.description)+" ")])])])]):_vm._e(),(
                                            row.type === 'event' &&
                                            row.search_type === 'api'
                                        )?_c('div',[_c('div',{staticClass:"d-flex p-2",staticStyle:{"gap":"15px"}},[_c('div',[_c('img',{attrs:{"width":"100px","src":row.picture}})]),_c('div',[_c('div',[_c('a',{attrs:{"href":row.url}},[_vm._v(_vm._s(row.name))])]),_c('div',[_vm._v(" "+_vm._s(row.location)+" ")])])])]):_vm._e(),(
                                            row.type === 'user' &&
                                            row.platform === 'twitter'
                                        )?_c('div',[_c('div',{staticClass:"d-flex p-2",staticStyle:{"gap":"15px"}},[_c('div',[_c('img',{attrs:{"width":"100px","src":row.profile_photo_url}})]),_c('div',[_c('div',[_c('a',{attrs:{"target":"_blank","href":""}},[_vm._v(_vm._s(row.name))]),_c('span',[_c('small',[_vm._v(_vm._s(_vm.toDate( row.created )))])])]),_c('div',[_vm._v(" "+_vm._s(row.screen_name)+" ")]),(!_vm.item.verified)?_c('div',[_c('button',{staticClass:"btn btn-sm btn-primary",on:{"click":function($event){return _vm.verify(row)}}},[_vm._v(" Verify ")])]):_vm._e(),(
                                                        _vm.item.verified ==
                                                        row.id
                                                    )?_c('div',[_c('i',{staticClass:"fa fa-check text-success"}),_c('span',{staticClass:"text-success"},[_vm._v("Verified")])]):_vm._e()])])]):_vm._e(),(
                                            row.type === 'user' &&
                                            row.platform === 'facebook' &&
                                            row.search_type === 'api'
                                        )?_c('div',[_c('div',{staticClass:"d-flex p-2",staticStyle:{"gap":"15px"}},[_c('div',[_c('img',{attrs:{"width":"100px","src":row.photoUrl}})]),_c('div',[_c('div',[_c('a',{attrs:{"target":"_blank","href":row.url}},[_vm._v(_vm._s(row.name))])]),_c('div',[_vm._v(_vm._s(row.info))])])])]):_vm._e(),(
                                            row.type === 'user' &&
                                            row.platform === 'facebook' &&
                                            row.search_type === 'browser'
                                        )?_c('div',[_c('div',{staticClass:"d-flex p-2",staticStyle:{"gap":"15px"}},[_c('div',[_c('img',{attrs:{"width":"100px","src":row.profile_picture}})]),_c('div',[_c('div',[_c('a',{attrs:{"target":"_blank","href":row.profile_url}},[_vm._v(_vm._s(row.name))]),_c('span',[_c('small',[_vm._v("verified: "+_vm._s(row.is_verified)+"}")])])]),_c('div',[_vm._v(" "+_vm._s(row.screen_name)+" ")])])])]):_vm._e(),(
                                            row.type === 'group' &&
                                            row.search_type === 'api'
                                        )?_c('div',[_c('div',{staticClass:"d-flex p-2",staticStyle:{"gap":"15px"}},[_c('div',[_c('img',{attrs:{"width":"100px","src":row.photoUrl}})]),_c('div',[_c('div',[_c('a',{attrs:{"target":"_blank","href":row.url}},[_vm._v(_vm._s(row.name))])]),_c('div',[_c('small',[_vm._v("Members - "+_vm._s(row.members))])])])])]):_vm._e(),(
                                            row.type === 'group' &&
                                            row.search_type === 'browser'
                                        )?_c('div',[_c('div',{staticClass:"d-flex p-2",staticStyle:{"gap":"15px"}},[_c('div',[_c('img',{attrs:{"width":"100px","src":row.picture}})]),_c('div',[_c('div',[_c('a',{attrs:{"target":"_blank","href":row.url}},[_vm._v(_vm._s(row.name))])])])])]):_vm._e(),(
                                            row.type === 'video' &&
                                            row.search_type === 'api'
                                        )?_c('div',{staticClass:"image-container"},[_c('a',{attrs:{"target":"_blank","href":row.url}},[_c('img',{attrs:{"height":"200","src":row.thumbnail_url}})])]):_vm._e(),(
                                            row.type === 'video' &&
                                            row.search_type === 'browser'
                                        )?_c('div',{staticClass:"image-container"},[_c('a',{attrs:{"target":"_blank","href":row.url}},[_c('img',{attrs:{"height":"200","src":row.thumbnail}})])]):_vm._e(),(
                                            row.type === 'photo' &&
                                            row.search_type === 'api'
                                        )?_c('div',{staticClass:"image-container"},[_c('a',{attrs:{"target":"_blank","href":row.url}},[(
                                                    row.attachments &&
                                                    row.attachments.length
                                                )?_c('div',[_c('img',{attrs:{"height":"200","src":row.attachments[0]
                                                            .preview_image}})]):_vm._e()])]):_vm._e(),(
                                            row.type === 'photo' &&
                                            row.search_type === 'browser'
                                        )?_c('div',{staticClass:"image-container"},[_c('a',{attrs:{"target":"_blank","href":row.url}},[(row.image)?_c('div',[_c('img',{attrs:{"height":"200","src":row.image}})]):_vm._e()])]):_vm._e(),(row.contact_info)?_c('div',[_vm._v(" about ")]):_vm._e(),(
                                            row.type === 'contactInfo' &&
                                            row.platform === 'linkedin'
                                        )?_c('div',[_c('contact-info',{attrs:{"contact":row}})],1):_vm._e()])}),0)])})],2)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }