<template>
    <details-component header="People also viewed" style="min-width: 100%">
        <b-list-group flush style="padding: 2rem">
            <b-list-group-item v-for="(person, index) in visiblePeople" :key="index">
                <b-card-text>
                    <b-row>
                        <b-col cols="2">
                            <b-img :src="person.profile_picture" blank-color="blue" fluid class="profile-pic"></b-img>
                        </b-col>
                        <b-col cols="10">
                            <h6>
                                <b-link :href="person.url" target="_blank" style="color: #000000">
                                    {{ person.first_name }} {{ person.last_name }}
                                </b-link>
                                <i class="fa fa-linkedin" v-if="person.premium"></i>
                            </h6>

                            <p>{{ person.headline }}</p>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-list-group-item>
        </b-list-group>
        <b-button variant="light" class="btn-show-details" v-if="people.length > 4" @click="showModal = true">
            Show all {{ people.length }} people
            <i class="fa fa-arrow-right" style="font-weight: lighter"></i>
        </b-button>
        <Modal id="people_modal" title="People also viewed" :visible.sync='showModal' @close="showModal = false">
            <b-list-group flush style="padding: 2rem">
                <b-list-group-item v-for="(person, index) in people" :key="index">
                    <b-card-text>
                        <b-row>
                            <b-col cols="2">
                                <b-img :src="person.profile_picture" blank-color="blue" fluid class="profile-pic"></b-img>
                            </b-col>
                            <b-col cols="10">
                                <h6>
                                    <b-link :href="person.url" target="_blank" style="color: #000000">
                                        {{ person.first_name }} {{ person.last_name }}
                                    </b-link>
                                    <i class="fa fa-linkedin" v-if="person.premium"></i>
                                </h6>

                                <p>{{ person.headline }}</p>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-list-group-item>
            </b-list-group>
        </Modal>
    </details-component>
</template>
<script>
import DetailsComponent from "@/components/scraper/linkedin/results/utils/details_component.vue";
import Modal from "@/components/scraper/linkedin/results/utils/modal.vue";

export default {
    name: "PeopleAlsoViewed",
    components: {Modal, DetailsComponent},
    computed: {
        visiblePeople() {
            return this.people.slice(0, 4);
        },
    },
    props: {
        people: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            showModal: false,
        };
    },
};
</script>
<style scoped>
.fa-linkedin {
    background-color: #f0a500;
    color: white;
    padding: 2px;
    font-size: 1rem;
    margin-left: 1rem;
    border-radius: 2px;
    border: solid 1px #f0a500;
    position: absolute;
}

.profile-pic {
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.btn-show-details {
    margin: 0;
    width: 100%;
    border-radius: 0 0 12px 12px;
}
</style>
