<template>
    <!-- The Modal -->
    <div class="modal fade" id="configEditModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Edit Configuration</h4>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        @click="$emit('close')"
                    >
                        &times;
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row editor">
                            <div class="col-12 col-sm-12">
                                <div class="form" role="form" style="">
                                    <!-- section one -->
                                    <div class="row">
                                        <div class="col-12">
                                            <h5>Settings</h5>
                                            <br />
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label
                                                    >Platform
                                                    <strong class="text-danger"
                                                        >*</strong
                                                    ></label
                                                >
                                                <select
                                                    v-model="item.platform"
                                                    class="form-control text-capitalize required"
                                                    required
                                                    @change="this.users = []"
                                                >
                                                    <option
                                                        v-for="(
                                                            platform, index
                                                        ) in platforms"
                                                        :key="index"
                                                        :value="platform"
                                                    >
                                                        {{ platform }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label
                                                    >Search Type
                                                    <strong class="text-danger"
                                                        >*</strong
                                                    ></label
                                                >
                                                <select
                                                    v-model="item.search_type"
                                                    class="form-control text-capitalize required"
                                                    required
                                                >
                                                    <option
                                                        v-if="
                                                            item.platform ===
                                                            'instagram'
                                                        "
                                                        value="api"
                                                    >
                                                        api
                                                    </option>
                                                    <option
                                                        v-else
                                                        v-for="(
                                                            type, index
                                                        ) in types"
                                                        :key="index"
                                                        :value="type"
                                                    >
                                                        {{ type }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div
                                            class="col-md-6 col-sm-12"
                                            v-if="
                                                item.platform !== 'linkedin' &&
                                                item.search_type !== 'api'
                                            "
                                        >
                                            <div class="form-group">
                                                <label>
                                                    Data Points
                                                    <strong class="text-danger">
                                                        *
                                                    </strong>
                                                </label>
                                                <ejs-multiselect
                                                    id="sample-list2"
                                                    v-model="item.points"
                                                    cssClass="e-outline"
                                                    :dataSource="dataSource"
                                                    mode="Box"
                                                ></ejs-multiselect>
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-12"
                                            v-if="
                                                item.platform === 'linkedin' &&
                                                item.search_type === 'api'
                                            "
                                        >
                                            <div class="form-group">
                                                <label>
                                                    Data Points
                                                    <strong class="text-danger">
                                                        *
                                                    </strong>
                                                </label>
                                                <linkedin-multiselect
                                                    :item="item"
                                                />
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label
                                                    >Status
                                                    <strong class="text-danger"
                                                        >*</strong
                                                    ></label
                                                >
                                                <select
                                                    v-model="item.status"
                                                    class="form-control text-capitalize required"
                                                    required
                                                >
                                                    <option
                                                        v-for="(
                                                            status, index
                                                        ) in statuses"
                                                        :key="index"
                                                        :value="status"
                                                    >
                                                        {{ status }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label
                                                    >Frequency
                                                    <strong class="text-danger"
                                                        >*</strong
                                                    ></label
                                                >
                                                <select
                                                    v-model="item.frequency"
                                                    class="form-control text-capitalize required"
                                                    required
                                                >
                                                    <option
                                                        v-for="(
                                                            frequency, index
                                                        ) in frequencies"
                                                        :key="index"
                                                        :value="frequency"
                                                    >
                                                        {{ frequency }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label
                                                    >Date Range
                                                    <strong class="text-danger"
                                                        >*</strong
                                                    ></label
                                                >
                                                <ejs-daterangepicker
                                                    cssClass="e-outline"
                                                    v-model="item.daterange"
                                                ></ejs-daterangepicker>
                                            </div>
                                        </div>

                                        <div
                                            v-if="item.search_type == 'browser'"
                                            class="col-md-6 col-sm-12"
                                        >
                                            <div class="form-group">
                                                <label
                                                    >Proxy
                                                    <strong class="text-danger"
                                                        >*</strong
                                                    ></label
                                                >
                                                <select
                                                    v-model="item.proxy"
                                                    class="form-control text-capitalize required"
                                                    required
                                                >
                                                    <option
                                                        v-for="(
                                                            proxy, index
                                                        ) in proxies"
                                                        :key="index"
                                                        :value="proxy"
                                                    >
                                                        {{ proxy }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <template
                                            v-if="
                                                item.search_type == 'browser' &&
                                                item.proxy === 'yes'
                                            "
                                        >
                                            <div class="col-md-6 col-sm-12">
                                                <div class="form-group">
                                                    <label
                                                        >Proxy Country
                                                        <strong
                                                            class="text-danger"
                                                            >*</strong
                                                        ></label
                                                    >
                                                    <select
                                                        v-model="
                                                            item.proxy_country
                                                        "
                                                        @change="loadstates"
                                                        class="form-control"
                                                    >
                                                        <option
                                                            value=""
                                                            selected
                                                        >
                                                            -- Any Country --
                                                        </option>
                                                        <option
                                                            v-for="country in countries"
                                                            :key="country._id"
                                                            :value="
                                                                country.code
                                                            "
                                                        >
                                                            {{ country.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div
                                                v-if="item.proxy_country"
                                                class="col-md-6 col-sm-12"
                                            >
                                                <div class="form-group">
                                                    <label
                                                        >Proxy State
                                                        <strong
                                                            class="text-danger"
                                                        ></strong
                                                    ></label>
                                                    <select
                                                        v-model="
                                                            item.proxy_state
                                                        "
                                                        @change="loadcities"
                                                        class="form-control"
                                                    >
                                                        <option
                                                            value=""
                                                            selected
                                                        >
                                                            -- Any State --
                                                        </option>
                                                        <option
                                                            v-for="state in states"
                                                            :key="state"
                                                            :value="state"
                                                        >
                                                            {{ state }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div
                                                v-if="item.proxy_state"
                                                class="col-md-6 col-sm-12"
                                            >
                                                <div class="form-group">
                                                    <label
                                                        >Proxy City
                                                        <strong
                                                            class="text-danger"
                                                        ></strong
                                                    ></label>
                                                    <select
                                                        v-model="
                                                            item.proxy_city
                                                        "
                                                        class="form-control"
                                                    >
                                                        <option
                                                            value=""
                                                            selected
                                                        >
                                                            -- Any City --
                                                        </option>
                                                        <option
                                                            v-for="city in cities"
                                                            :key="city._id"
                                                            :value="
                                                                city.city_name
                                                            "
                                                        >
                                                            {{ city.city_name }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </template>
                                        <div
                                            :class="`${
                                                item.platform === 'facebook'
                                                    ? 'col-sm-9'
                                                    : 'col-sm-12'
                                            }`"
                                        >
                                            <div
                                                class="form-group"
                                                v-if="
                                                    item.platform !== 'linkedin'
                                                "
                                            >
                                                <label>
                                                    Search User
                                                    <strong class="text-danger">
                                                        *
                                                    </strong>
                                                </label>
                                                <input
                                                    type="text"
                                                    v-model="item.search"
                                                    class="form-control"
                                                    @input="
                                                        debouncedInputChange
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <!--LINKEDIN EXTRA FIELDS STARTS-->

                                        <div
                                            v-if="
                                                item.platform === 'linkedin' &&
                                                item.points &&
                                                item.points.some(
                                                    (p) => p === 'people'
                                                )
                                            "
                                            class="col-md-6 col-sm-12"
                                        >
                                            <div class="form-group">
                                                <label>
                                                    Name
                                                    <strong class="text-danger">
                                                        *
                                                    </strong>
                                                </label>
                                                <input
                                                    type="text"
                                                    v-model="item.name"
                                                    class="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                item.platform === 'linkedin' &&
                                                item.points &&
                                                item.points.some(
                                                    (p) => p === 'people'
                                                )
                                            "
                                            class="col-md-6 col-sm-12"
                                        >
                                            <div class="form-group">
                                                <label> Surname </label>
                                                <strong class="text-danger">
                                                    *
                                                </strong>
                                                <input
                                                    type="text"
                                                    v-model="item.surname"
                                                    class="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            v-if="item.platform === 'linkedin'"
                                            class="col-md-6 col-sm-12"
                                        >
                                            <div class="form-group">
                                                <label>Keywords</label>
                                                <strong class="text-danger">
                                                    *
                                                </strong>
                                                <input
                                                    type="text"
                                                    v-model="item.keywords"
                                                    class="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                item.platform === 'linkedin' &&
                                                item.points &&
                                                item.points.some(
                                                    (p) => p === 'company'
                                                )
                                            "
                                            class="col-md-6 col-sm-12"
                                        >
                                            <div class="form-group">
                                                <label>
                                                    Company Name
                                                    <strong class="text-danger">
                                                        *
                                                    </strong>
                                                </label>
                                                <input
                                                    type="text"
                                                    v-model="item.company_name"
                                                    class="form-control"
                                                />
                                            </div>
                                        </div>
                                        <!--LINKEDIN EXTRA FIELDS ENDS-->
                                        <div
                                            v-if="item.platform === 'facebook'"
                                            class="col-sm-3"
                                        >
                                            <div class="form-group">
                                                <label
                                                    >Search Category
                                                    <strong class="text-danger">
                                                        *
                                                    </strong>
                                                </label>
                                                <select
                                                    v-model="
                                                        item.search_category
                                                    "
                                                    @change="searchUser"
                                                    class="form-control"
                                                >
                                                    <option
                                                        value="users"
                                                        selected
                                                    >
                                                        People
                                                    </option>
                                                    <option value="pages">
                                                        Pages
                                                    </option>
                                                    <option value="groups">
                                                        Groups
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="item.verified" class="col-12 col-sm-12">
                                <div v-if="item.platform === 'twitter'">
                                    <div class="d-flex p-2" style="gap: 15px">
                                        <div>
                                            <img
                                                width="100px"
                                                :src="
                                                    item.verified
                                                        .profile_photo_url
                                                "
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <a target="_blank" href="">{{
                                                    item.verified.name
                                                }}</a>
                                                <span
                                                    ><small>{{
                                                        toDate(
                                                            item.verified
                                                                .created
                                                        )
                                                    }}</small></span
                                                >
                                            </div>
                                            <div>
                                                {{ item.verified.screen_name }}
                                            </div>
                                            <div>
                                                <i
                                                    class="fa fa-check text-success"
                                                ></i>
                                                <span class="text-success"
                                                    >Verified</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="item.platform === 'facebook'">
                                    <div class="d-flex p-2" style="gap: 15px">
                                        <div>
                                            <img
                                                width="100px"
                                                :src="item.verified.photoUrl"
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <a
                                                    target="_blank"
                                                    :href="item.verified.url"
                                                    >{{ item.verified.name }}</a
                                                >
                                            </div>
                                            <div>{{ item.verified.info }}</div>
                                            <div>
                                                <i
                                                    class="fa fa-check text-success"
                                                ></i>
                                                <span class="text-success"
                                                    >Verified</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="item.platform === 'instagram'">
                                    <div class="d-flex p-2" style="gap: 15px">
                                        <div>
                                            <ImageLoader
                                                :image-url="
                                                    item.verified
                                                        .profile_pic_url
                                                "
                                                :style="{
                                                    width: '100px',
                                                    height: '100px',
                                                }"
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <a
                                                    target="_blank"
                                                    :href="
                                                        item.verified.username
                                                    "
                                                    >{{
                                                        item.verified.full_name
                                                    }}</a
                                                >
                                            </div>
                                            <div>
                                                @{{ item.verified.username }}
                                            </div>
                                            <div>
                                                <i
                                                    class="fa fa-check text-success"
                                                ></i>
                                                <span class="text-success"
                                                    >Verified</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-else
                                class="col-12 col-sm-12"
                                v-infinite-scroll="searchCursor"
                                infinite-scroll-distance="10"
                            >
                                <div
                                    v-for="(row, idx) in users"
                                    :key="idx"
                                    class="shadow-sm mb-4"
                                >
                                    <div v-if="item.platform === 'twitter'">
                                        <div
                                            class="d-flex p-2"
                                            style="gap: 15px"
                                        >
                                            <div>
                                                <img
                                                    width="100px"
                                                    :src="row.profile_photo_url"
                                                />
                                            </div>
                                            <div>
                                                <div>
                                                    <a
                                                        target="_blank"
                                                        href=""
                                                        >{{ row.name }}</a
                                                    >
                                                    <span
                                                        ><small>{{
                                                            toDate(row.created)
                                                        }}</small></span
                                                    >
                                                </div>
                                                <div>{{ row.screen_name }}</div>

                                                <div v-if="!item.verified">
                                                    <button
                                                        class="btn btn-sm btn-primary"
                                                        @click="verify(row)"
                                                    >
                                                        Verify
                                                    </button>
                                                </div>
                                                <div
                                                    v-if="
                                                        item.verified == row.id
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-check text-success"
                                                    ></i>
                                                    <span class="text-success"
                                                        >Verified</span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="item.platform === 'facebook'">
                                        <div
                                            class="d-flex p-2"
                                            style="gap: 15px"
                                        >
                                            <div>
                                                <img
                                                    width="100px"
                                                    :src="row.photoUrl"
                                                />
                                            </div>
                                            <div>
                                                <div>
                                                    <a
                                                        target="_blank"
                                                        :href="row.url"
                                                        >{{ row.name }}</a
                                                    >
                                                </div>
                                                <div>{{ row.info }}</div>

                                                <div v-if="!item.verified">
                                                    <button
                                                        class="btn btn-sm btn-primary"
                                                        @click="verify(row)"
                                                    >
                                                        Verify
                                                    </button>
                                                </div>
                                                <div
                                                    v-if="
                                                        item.verified == row.id
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-check text-success"
                                                    ></i>
                                                    <span class="text-success"
                                                        >Verified</span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="item.platform === 'instagram'">
                                        <div
                                            class="d-flex p-2"
                                            style="gap: 15px"
                                        >
                                            <div>
                                                <ImageLoader
                                                    :image-url="
                                                        row.profile_pic_url
                                                    "
                                                    :style="{
                                                        width: '100px',
                                                        height: '100px',
                                                    }"
                                                />
                                            </div>
                                            <div>
                                                <div>
                                                    <a
                                                        target="_blank"
                                                        :href="row.username"
                                                        >{{ row.full_name }}</a
                                                    >
                                                </div>
                                                <div>@{{ row.username }}</div>

                                                <div v-if="!item.verified">
                                                    <button
                                                        class="btn btn-sm btn-primary"
                                                        @click="verify(row)"
                                                    >
                                                        Verify
                                                    </button>
                                                </div>
                                                <div
                                                    v-if="
                                                        item.verified == row.pk
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-check text-success"
                                                    ></i>
                                                    <span class="text-success"
                                                        >Verified</span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="row d-flex justify-content-center m-4"
                                    v-if="loading"
                                >
                                    <img src="/loading.gif" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="row pt-4">
                        <div class="col-12">
                            <div class="form-group text-center">
                                <button
                                    v-if="!item._id"
                                    type="submit"
                                    class="btn btn-primary btn-sm"
                                    @click.prevent="save"
                                >
                                    <span class="glyphicon glyphicon-ok"></span>
                                    Create Configuration
                                </button>
                                <button
                                    v-else
                                    type="submit"
                                    class="btn btn-primary btn-sm"
                                    @click.prevent="update"
                                >
                                    <span class="glyphicon glyphicon-ok"></span>
                                    Update Configuration
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import {
    MultiSelectComponent,
    CheckBoxSelection,
} from "@syncfusion/ej2-vue-dropdowns";

import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import axios from "axios";
import { debounce } from "lodash";
import swal from "sweetalert";
import moment from "moment/moment";
import ImageLoader from "@/components/instagramIntegration/imageLoader.vue";
import LinkedinMultiselect from "@/components/scraper/linkedin/linkedin-multiselect.vue";

Vue.use(DateRangePickerPlugin);
let infiniteScroll = require("vue-infinite-scroll");
export default {
    name: "ConfigModal",
    props: ["item", "save", "update"],
    directives: {
        infiniteScroll,
    },
    provide: {
        multiSelect: [CheckBoxSelection],
    },
    components: {
        LinkedinMultiselect,
        ImageLoader,
        "ejs-multiselect": MultiSelectComponent,
    },
    data() {
        return {
            args: {},
            ready: false,
            loading: false,
            cursor: null,
            search: null,
            isLoading: false,
            cancelTokenSource: null,
            providers: [],
            countries: [],
            states: [],
            cities: [],
            users: [],
            fbp: ["Posts", "Events", "People", "Photos", "Groups", "Videos"],
            instagramP: ["Profiles", "Posts"],
            twp: [
                "Latest",
                "Media",
                "People",
                "List",
                "Overview",
                "Activity",
                "Contact Info",
            ],
            types: ["api", "browser"],
            proxies: ["yes", "no"],
            platforms: ["facebook", "twitter", "instagram", "linkedin"],
            statuses: ["active", "disabled"],
            frequencies: ["hourly", "daily", "monthly"],
        };
    },
    computed: {
        dataSource() {
            switch (this.item.platform) {
                case "facebook":
                    return this.fbp;
                case "instagram":
                    return this.instagramP;
                case "twitter":
                    return this.twp;
                case "linkedin":
                    return this.linkedin;
                default:
                    return [];
            }
        },
    },
    mounted() {
        this.loadcountries();
    },
    created() {
        this.debouncedInputChange = debounce(this.searchUser, 600);
    },
    methods: {
        toDate(date) {
            return moment(date).format("YYYY-MM-DD hh:mm:ss");
        },
        verify(user) {
            if (!this.item._id) {
                console.log("no id");
                this.item.verified = user;
                this.$forceUpdate();
                return;
            }
            axios
                .put(`${this.$root.serverUrl}/admin/social/${this.item._id}`, {
                    args: { verified: user },
                })
                .then((resp) => {
                    console.log("request");
                    if (resp.data && resp.data.error) {
                        swal({
                            title: "Error",
                            text: "Unable to process your request",
                            icon: "error",
                        });
                    } else {
                        swal({
                            title: "Success",
                            text: "Account verified",
                            icon: "success",
                        });
                        this.item.verified = user;
                        this.$forceUpdate();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    swal({
                        title: "Error",
                        text: "Unable to process your request",
                        icon: "error",
                    });
                });
        },
        searchCursor: function () {
            var request = {
                search: this.item.search,
                cursor: this.cursor,
                search_category: this.item.search_category,
            };
            if (!this.cursor || this.loading) return;
            this.loading = true;

            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel(
                    "Operation canceled due to new request."
                );
            }

            this.cancelTokenSource = axios.CancelToken.source();

            return axios
                .get(
                    `${
                        this.$root.serverUrl
                    }/admin/social/${this.item.platform.toLowerCase()}/users`,
                    {
                        params: request,
                        cancelToken: this.cancelTokenSource.token,
                    }
                )
                .then((resp) => {
                    //Store the stats
                    if (!resp.data) return;
                    let users =
                        resp.data && resp.data.users ? resp.data.users : [];
                    this.users.push(...users);
                    this.cursor =
                        resp.data && resp.data.cursor ? resp.data.cursor : null;
                })
                .catch((err) => {
                    if (!axios.isCancel(err)) {
                        console.log(err);
                        swal({
                            title: "Error",
                            text: "Unable to process your request",
                            icon: "error",
                        });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        searchUser: function () {
            var request = {
                search: this.item.search,
                search_category: this.item.search_category,
            };
            this.loading = true;

            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel(
                    "Operation canceled due to new request."
                );
            }

            this.cancelTokenSource = axios.CancelToken.source();

            return axios
                .get(
                    `${
                        this.$root.serverUrl
                    }/admin/social/${this.item.platform.toLowerCase()}/users`,
                    {
                        params: request,
                        cancelToken: this.cancelTokenSource.token,
                    }
                )
                .then((resp) => {
                    //Store the stats
                    if (!resp.data) return;
                    this.users =
                        resp.data && resp.data.users ? resp.data.users : [];
                    this.cursor =
                        resp.data && resp.data.cursor ? resp.data.cursor : null;
                })
                .catch((err) => {
                    if (!axios.isCancel(err)) {
                        console.log(err);
                        swal({
                            title: "Error",
                            text: "Unable to process your request",
                            icon: "error",
                        });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        loadcountries: function () {
            var request = { apikey: this.$root.apikey, sSearch: "" };

            return axios
                .get(`${this.$root.serverUrl}/admin/countries`, {
                    params: request,
                })
                .then(
                    function (resp) {
                        //Store the stats
                        this.countries =
                            resp.data && resp.data.data ? resp.data.data : [];
                    }.bind(this)
                );
        },
        loadstates: function () {
            var request = { apikey: this.$root.apikey, filters: {} };

            if (this.item.proxy_country)
                request.filters.country_iso_code = this.item.proxy_country;

            return axios
                .get(`${this.$root.serverUrl}/admin/geos/state`, {
                    params: request,
                })
                .then(
                    function (resp) {
                        //Store the stats
                        this.states =
                            resp.data && resp.data.data ? resp.data.data : [];
                    }.bind(this)
                );
        },
        loadcities: function () {
            var request = { apikey: this.$root.apikey, filters: {} };

            if (this.item.proxy_country)
                request.filters.country_iso_code = this.item.proxy_country;
            if (this.item.proxy_state)
                request.filters.subdivision_1_iso_code = this.item.proxy_state;

            return axios
                .get(`${this.$root.serverUrl}/admin/geos/city`, {
                    params: request,
                })
                .then(
                    function (resp) {
                        //Store the stats
                        this.cities = resp.data ? resp.data.data : [];
                    }.bind(this)
                );
        },
    },
};
</script>

<style scoped>
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";

.modal-dialog {
    height: 90vh;
    display: flex;
    max-width: 800px;
    overflow: auto;
}

.modal-header {
    background: #0d0d48;
}

.modal-title {
    color: #ffffff;
}

.close {
    color: #ffffff;
}

.modal-body {
    overflow: auto;
}

.info-sec i {
    font-size: 100px;
}

.v-select2-container {
    width: 100%; /* Set the width to 100% */
}

.select2-container .select2-selection--single {
    height: 38px; /* Set the width to 100% */
}

.select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    line-height: 38px;
}
</style>
