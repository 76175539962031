<template>
    <b-container>
        <b-row :style="backgroundStyle" class="background-row">
            <b-col cols="10" class="d-flex profile-col">
                <b-img
                    :src="overview.profile_picture"
                    alt="Profile Picture"
                    class="profile-picture"
                ></b-img>
            </b-col>
            <b-col cols="2" class="d-flex justify-content-end align-items-center linkedin-col">
                <i class="fa fa-linkedin gold-icon" v-if="overview.has_premium"></i>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="8">
                <h2>
                    <b-link
                        :href="overview.url"
                        target="_blank"
                        style="color: black"
                    >

                        {{ overview.first_name }}
                        {{ overview.last_name }}

                    </b-link>
                </h2>
                <h6 v-if="overview.headline && overview.headline !== 'NULL' ">
                    <strong> {{ overview.headline }}</strong>
                </h6>
                <section class="section-creator" v-if="overview.creator_info">
                    <strong class="creator-label">Talks about</strong>
                    <div class="creator-info">
                        <span v-for="(hashtag, index) in overview.creator_info.associated_hashtag" :key="index">
                            {{ hashtag }}
                        </span>
                    </div>
                </section>
                <section class="location">
                    <template v-if="overview.location">
                        {{ overview.location.name }}, {{ overview.location.country.name }}
                    </template>
                    <template v-if="contactInfo">
                        • <ContactInfo :contactInfo="contactInfo" class="inline-contact-info"  />
                    </template>
                </section>
               <b-row>
                   <b-col cols="6">
                       <p>
                           <i class="fa fa-user-plus"></i>
                           <strong> {{ overview.connections }} connections</strong>
                       </p>
                   </b-col>
                   <b-col cols="6">
                       <p>
                           <i class="fa fa-user"></i>
                            <strong> {{ overview.followers }} followers</strong>
                       </p>
                   </b-col>
               </b-row>
            </b-col>
            <b-col cols="4">
                <b-row>
                    <b-col cols="3">
                        <b-img
                            v-if="overview.top_position.company_info.logo"
                            :src="overview.top_position.company_info.logo"
                            alt="Company Logo"
                            class="company-logo"
                        ></b-img>
                    </b-col>
                    <b-col cols="8">
                        <b-link
                            v-if="overview.top_position.company_info.name && overview.top_position.company_info.name !== 'NULL'"
                            :href="overview.top_position.company_info.url"
                            target="_blank"
                        >
                            <section>
                                {{
                                    overview.top_position
                                        .company_info
                                        .name
                                }}
                            </section>

                        </b-link>
                    </b-col>
                </b-row>
                <b-row v-if="overview.education">
                    <b-col cols="3">

                    </b-col>
                    <b-col cols="8">
                        <b-link
                            :href="overview.education.url"
                            target="_blank"
                        >
                            <section>
                                {{
                                    overview.education.name
                                }}
                            </section>

                        </b-link>
                    </b-col>
                </b-row>


            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import ContactInfo from "@/components/scraper/linkedin/results/people/contact_info.vue";

export default {
    name: "Overview",
    components: {ContactInfo},
    computed: {
        backgroundStyle() {
            return {
                backgroundImage: this.overview.background_picture ? `url(${this.overview.background_picture})` : 'none',
                backgroundColor: this.overview.background_picture ? 'transparent' : '#0d0d48',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            };
        }
    },
    props: {
        overview: {
            type: Object,
            required: true,
        },
        contactInfo: {
            type: Object,
            required: false,
        },
    },
};
</script>

<style scoped>

.image-grid img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.location {
    margin-top: 2px;
    margin-bottom: 10px;
}

.profile-picture {
    width: 125px;
    height: 125px;
    border-radius: 50%;
}

.company-logo {
    width: 50px;
    height: 50px;
}

.section-creator {
    margin-top: 10px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.38);
}

.creator-label {
    margin-right: 10px;
}

.creator-info span {
    display: inline;
    margin-right: 5px;
}

section {
    margin-top: 10px;
}

.gold-icon {
    background-color: #f0a500;
    color: white;
    padding: 6px;
    font-size: 1.4rem;
    border-radius: 4px;
    border: solid 1px #f0a500;
    position: absolute;
    top: 90%;
    transform: translateY(80%);
}

.background-row {
    background-size: cover;
    background-position: center;
    position: relative;
    height: 200px;
    margin-bottom: 75px;
}

.profile-col,
.linkedin-col {
    position: relative;
}

.profile-picture {
    position: absolute;
    top: 50%;
    transform: translateY(25%);
}

.inline-contact-info {
    display: inline;
}

</style>
