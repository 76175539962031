<template>
    <b-card class="list-group-item" >
        <h4 >{{ type }}</h4>
        <b-card-header>
            <b-row>
                <b-col cols="2">
                    <b-img
                        v-if="activity.author && activity.author.image_url"
                        :src="activity.author.image_url"
                        :alt="
                            activity.author.first_name +
                            ' ' +
                            activity.author.last_name
                        "
                        fluid
                        class="profile-picture"
                    ></b-img>
                </b-col>
                <b-col>
                    <template v-if="activity.author">
                        <h6>
                            <b-link target="_blank" :href="activity.author.url">
                                <strong>
                                    {{ activity.author.first_name }}
                                    {{ activity.author.last_name }}
                                </strong>
                            </b-link>
                        </h6>
                        <p v-if="activity.author.occupation">
                            {{ activity.author.occupation }}
                        </p>
                        <i class="fa fa-calendar mr-2"></i>
                        {{
                            new Date(activity.created_at).toDateString(
                                getOptions
                            )
                        }}
                    </template>
                    <template v-else>
                        <h5>Unknown Author</h5>
                    </template>
                </b-col></b-row>
        </b-card-header>
        <b-card-body>
            <p v-if="activity.text">
                {{ activity.text }}
            </p>
            <section v-if="activity.attachments && activity.attachments.length">
                <h6>Attachments</h6>
                <b-container fluid>
                    <b-row>
                        <b-col
                            v-for="(attachment, index) in activity.attachments"
                            :key="index"
                            :cols="activity.attachments.length > 1 ? '6': '12'" :sm="activity.attachments.length > 1 ? '6': '12'"
                        >
                            <b-card v-if="attachment.type === 'Article'" class="list-group-item">
                                <b-card-title>{{ attachment.title }}</b-card-title>
                                <b-card-text>{{ attachment.subtitle }}</b-card-text>
                                <b-link :href="attachment.url" target="_blank">
                                    Read more
                                </b-link>
                            </b-card>
                            <b-card v-if="attachment.type === 'Entity'" class="list-group-item">
                                <b-card-title>{{ attachment.title }}</b-card-title>
                                <b-card-text>{{ attachment.subtitle }}</b-card-text>
                                <b-card-text>{{ attachment.description }}</b-card-text>
                            </b-card>
                            <b-card v-if="attachment.type === 'Image'" class="list-group-item">
                                <b-img
                                    :src="attachment.image"
                                    fluid
                                    rounded
                                    style="min-width: 400px; max-height: 500px;"
                                ></b-img>
                            </b-card>
                            <b-card v-if="attachment.type === 'Video'">
                                <b-button
                                    variant="outline-dark"
                                    :href="activity.url"
                                    target="_blank"
                                >
                                    <i class="fa fa-play"></i>
                                    Watch video on LinkedIn
                                </b-button>
                            </b-card>
                            <b-card v-if="attachment.type === 'Document'">
                                <b-button
                                    variant="outline-dark"
                                    :href="activity.url"
                                    target="_blank"
                                >
                                    <i class="fa fa-file"></i>
                                    {{ attachment.title }} - View document on LinkedIn
                                </b-button>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-container>
            </section>
        </b-card-body>
        <b-card-footer>
            <b-col>
                <b-row>
                    <template
                        v-if="
                            activity.reactions && activity.reactions.length > 0
                        "
                    >
                        <b-button
                            variant="outline-primary"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Like"
                            class="m-1"
                        >
                            {{ getReactionCount("LIKE") }}
                            <i class="fa fa-thumbs-up"></i>
                        </b-button>
                        <b-button
                            variant="outline-success"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Praise"
                            class="m-1"
                        >
                            {{ getReactionCount("PRAISE") }}
                            <i class="fa fa-sign-language"></i>
                        </b-button>
                        <b-button
                            variant="outline-danger"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Empathy"
                            class="m-1"
                        >
                            {{ getReactionCount("EMPATHY") }}
                            <i class="fa fa-heart"></i>
                        </b-button>
                        <b-button
                            variant="outline-warning"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Interest"
                            class="m-1"
                        >
                            {{ getReactionCount("INTEREST") }}
                            <i class="fa fa-lightbulb-o"></i>
                        </b-button>
                        <b-button
                            variant="outline-info"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Appreciation"
                            class="m-1"
                        >
                            {{ getReactionCount("APPRECIATION") }}
                            <i class="fa fa-smile-o"></i>
                        </b-button>
                    </template>
                    <template v-else>
                        <p>No reactions available</p>
                    </template>
                </b-row>
                <b-row>
                    <b-button
                        variant="outline-dark"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Shares"
                        class="m-1"
                        v-if="activity.shares !== undefined"
                    >
                        {{ activity.shares }}
                        <i class="fa fa-share"></i>
                    </b-button>
                    <b-button variant="outline-dark" v-else>No shares</b-button>
                    <b-button
                        variant="outline-dark"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Comments"
                        class="m-1"
                        v-if="activity.comments !== undefined"
                    >
                        {{ activity.comments }}
                        <i class="fa fa-comment"></i>
                    </b-button>
                    <b-button variant="outline-dark" v-else
                        >No comments</b-button
                    >
                    <b-button
                        variant="outline-dark"
                        :href="activity.url"
                        target="_blank"
                        class="m-1"
                    >
                        <i class="fa fa-linkedin"></i>
                        View on LinkedIn
                    </b-button>
                </b-row>
            </b-col>
        </b-card-footer>
    </b-card>
</template>
<script>
export default {
    name: "LinkedinActivity",
    props: {
        activity: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    methods: {
        getOptions() {
            return {
                year: "numeric",
                month: "long",
                day: "numeric",
            };
        },
        getReactionCount(type) {
            const reaction =
                this.activity.reactions &&
                this.activity.reactions.find((r) => r.reaction_type === type);
            return reaction ? reaction.count : 0;
        },
    },
};
</script>

<style scoped>

.profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.card-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.card-overlay .card-body {
    position: relative;
    z-index: 2;
}

.list-group-item {
    border: none;
}
</style>
